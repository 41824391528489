import { Pipe, PipeTransform } from '@angular/core';
import { Story } from '@interfaces/story';
import { LanguageService } from '@services/language-service/language.service';

/**
 * @param story: Story
 * @param language: "target" | "origin"
 * @param trim (optional): true | false
 * 
 *
 * Pipe will return the title of the given story. Based on the langauge input
 * it will return the title for the target or origin languge. 
 * Optional in trimming the title after 60 chars and add "..." to the return value.
 * TODO: add max chars for trimming as an optional parameter
 */
@Pipe({
  name: 'storyTitle',
  pure: true
})
export class StoryTitlePipe implements PipeTransform {

  constructor(
    private languageService: LanguageService
  ) { }

  transform(story: Story, args: string, trim?: boolean): any {
    if (!story?.titles) return '';
    if (trim) {
      trim = true;
    } else {
      trim = false;
    }

    let lang;
    let title;
    if (args == 'origin') lang = this.languageService.origin;
    if (args == 'target') lang = this.languageService.target;
    if (this.languageService.origin == 'ja' || this.languageService.origin == 'zh-hans' && this.languageService.translations != this.languageService.target) {
      lang = this.languageService.translations;
    }
    title = this.getTitle(story, lang);
    if (title == '' || title == null) {
      title = this.getTitle(story, 'en')
    }

    if (trim && title.length > 60) {
      let maxLength = 60;
      //trim the string to the maximum length
      let trimmedString = title.substr(0, maxLength);
      //re-trim if we are in the middle of a word
      trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))
    }

    return title;
  }

  getTitle(story: Story, language: string) {
    for (let index = 0; index < story.titles.length; index++) {
      const element = story.titles[index];
      if (element.language === language) {
        if (element.text == '') break;
        return element.text;
      }
    }
    if (language === 'eng') return this.getTitle(story, 'en');
    if (language === 'zh-hant') return this.getTitle(story, 'zh-TW');
    if (language === 'zh-hans') return this.getTitle(story, 'zh');
    return '';
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from './header/header.component';
import { DesktopHeaderComponent } from './desktop-header/desktop-header.component';
import { FooterComponent } from './footer/footer.component';
import { VoucherDialogComponent } from './voucher-dialog/voucher-dialog.component';
import { TrainingSentenceComponent } from './training-sentence/training-sentence.component';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { LoginFormComponent } from './auth/login-form/login-form.component';
import { RegisterFormComponent } from './auth/register-form/register-form.component';
import { ValidationCodeComponent } from './auth/validation-code/validation-code.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    ReactiveFormsModule,
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    DesktopHeaderComponent,
    VoucherDialogComponent,
    TrainingSentenceComponent,
    LoginModalComponent,
    LoginFormComponent,
    RegisterFormComponent,
    ValidationCodeComponent
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    DesktopHeaderComponent,
    VoucherDialogComponent,
    TrainingSentenceComponent,
    LoginModalComponent,
    LoginFormComponent,
    RegisterFormComponent,
    ValidationCodeComponent
  ],
})
export class ComponentsModule {}

<ion-content [ngClass]="{'dark-page': utilsService.isDarkMode}">
  <div class="bar">
    <div class="back-btn" (click)="back()" *ngIf="!isNarrow && !onboardingSerice.fromPromotion">
      <ion-icon name="arrow-back-outline"></ion-icon>
    </div>
    <div class="back-btn-x-promo" (click)="modalSkipPromo()" *ngIf="onboardingSerice.fromPromotion && !isNarrow">
      <ion-icon name="close"></ion-icon>
    </div>
  </div>
  <div class="grid shadow">
    <div class="card-payment-left">
      <h1 *ngIf="this.purchaseService.webappSelectedPlan == 'yearly'">
        {{this.languageService.label.web_payment_screen_plan_yearly}}</h1>
      <h1 *ngIf="this.purchaseService.webappSelectedPlan == 'monthly'">
        {{this.languageService.label.web_payment_screen_plan_monthly}}</h1>
      <h1 *ngIf="this.purchaseService.webappSelectedPlan == 'lifetime'">
        {{this.languageService.label.web_payment_screen_plan_lifetime}}</h1>

      <div class="back-btn-x" (click)="back()" *ngIf="isNarrow">
        <ion-icon name="close"></ion-icon>
      </div>

      <div class="mini grid">
        <div class="plan-title">
          <ion-label>{{this.languageService.label.web_payment_screen_plan_selection}} </ion-label>
          <!-- <ion-icon class="recommend-icon" name="ribbon-outline"></ion-icon> -->
        </div>
        <div>
          <ion-label class="plan-detail" *ngIf="this.purchaseService.webappSelectedPlan == 'yearly'"
            [innerHTML]="this.languageService.label.web_payment_screen_plan_yearly_duration"></ion-label>
          <ion-label class="plan-detail" *ngIf="this.purchaseService.webappSelectedPlan == 'monthly'"
            [innerHTML]="this.languageService.label.web_payment_screen_plan_monthly_duration"></ion-label>
          <ion-label class="plan-detail" *ngIf="this.purchaseService.webappSelectedPlan == 'lifetime'"
            [innerHTML]="this.languageService.label.web_payment_screen_plan_lifetime_duration"></ion-label>
          <p class="other-plans" (click)="viewOtherPlans()" *ngIf="!purchaseService.skipTrial&& !this.loading">
            {{this.languageService.label.web_payment_screen_plan_other_plans}}</p>
          <p *ngIf="purchaseService.skipTrial" class="spacing"></p>
        </div>
        <div *ngIf="isMobile" class="divider"></div>

        <div class="plan-title">
          <ion-label>{{this.languageService.label.web_payment_screen_billed_title}}:</ion-label>
          <p class="promo-cta" [innerHTML]="languageService.label.web_discount_promocode" (click)="openModal()"></p>
        </div>

        <div *ngIf="this.purchaseService.webappSelectedPlan == 'yearly'">
          <ng-container>
            <ion-label>
              <span class="plan-detail" *ngIf="!(this.languageService.target == 'ko') && !this.loading">
                <span *ngIf="!usedTrial&&!purchaseService.skipTrial&&!purchaseService.skipTrial">
                  {{ insertSpaceBeforeNumber(0 | currency: purchaseService.getUserCurrency())}}
                </span>

                <span *ngIf="this.couponObject" class="strike">
                  {{ webYearlyPrice }} <br>
                </span>
                <span *ngIf="usedTrial|| purchaseService.skipTrial ">
                  {{ promoPrice }}
                </span>
                {{this.languageService.label.web_payment_screen_billed_txt1}}
              </span>


              <span class="plan-detail" *ngIf="this.languageService.target == 'ko'">
                <span *ngIf="!usedTrial&&!purchaseService.skipTrial&& !this.loading">오늘은 무료</span>
                <span *ngIf="usedTrial|| purchaseService.skipTrial && !this.loading">
                  {{ webYearlyPrice }} {{this.languageService.label.web_payment_screen_billed_txt1}}</span>
              </span>
            </ion-label>
            <div class="plan-detail-text" *ngIf="!usedTrial&&!purchaseService.skipTrial&& !this.loading"
              [innerHTML]="YearTrialLabel">
            </div>
            <div class="plan-detail-text" *ngIf="usedTrial || purchaseService.skipTrial" [innerHTML]="YearNoTrialLabel">
            </div>
            <p *ngIf="purchaseService.skipTrial" class="spacing"></p>

          </ng-container>

        </div>


        <div *ngIf="this.purchaseService.webappSelectedPlan == 'monthly'">
          <ion-label>
            <span class="plan-detail">
              <span *ngIf="this.couponObject" class="strike">
                {{ webMonthlyPrice }} <br>
              </span>
              <span>{{ promoPrice }}</span> </span>

            {{this.languageService.label.web_payment_screen_billed_txt1}}
          </ion-label>
          <div class="plan-detail-text" [innerHTML]="this.languageService.label.web_payment_screen_billed_monthly_now">
          </div>
        </div>


        <div *ngIf="this.purchaseService.webappSelectedPlan == 'lifetime'">
          <ion-label>
            <span class="plan-detail">
              <span *ngIf="this.couponObject" class="strike">
                {{ webLifetimePrice }} <br>
              </span>
              <span>{{ promoPrice }}</span> </span>

            {{this.languageService.label.web_payment_screen_billed_txt1}}
          </ion-label>
        </div>
      </div>

      <hr *ngIf="!isNarrow" />
      <ng-container
        *ngIf="!usedTrial&&!purchaseService.skipTrial && !isNarrow && this.purchaseService.webappSelectedPlan == 'yearly'">
        <h5 [innerHTML]="languageService.label.web_payment_trail_reminder_headline"></h5>

        <div class="content">
          <img src="../../../assets/onboarding/gfx-explain-trail-dark.svg" alt="">
          <div>
            <section>
              <h5>{{languageService.label.txt_trail_reminder_top_headline}}</h5>
              <p [innerHTML]="languageService.label.txt_trail_reminder_top_text"></p>
            </section>

            <section>
              <h5>{{languageService.label.txt_trail_reminder_middle_headline}}</h5>
              <p [innerHTML]="languageService.label.txt_trail_reminder_middle_text"></p>
            </section>

            <section>
              <h5>{{languageService.label.txt_trail_reminder_bottom_headline}}</h5>
              <p [innerHTML]="languageService.label.txt_trail_reminder_bottom_text"></p>
            </section>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="(this.purchaseService.webappSelectedPlan == 'monthly' && !isNarrow) || (usedTrial && !isNarrow && this.purchaseService.webappSelectedPlan == 'yearly') || (this.purchaseService.webappSelectedPlan == 'lifetime' && !isNarrow)||
        purchaseService.skipTrial  && !isNarrow">
        <h5 class="monthly-left" [innerHTML]="languageService.label.web_payment_screen_billed_monthly_usp_title"></h5>
        <div>
          <div class="usps">
            <div class="usp">
              <img src="assets/svg/rounded-box-primary.svg" alt="">
              <div [innerHTML]="this.languageService.label.txt_credit_card_shop_benefit1"></div>
            </div>
            <div class="usp">
              <img src="assets/svg/rounded-box-primary.svg" alt="">
              <div [innerHTML]="this.languageService.label.txt_credit_card_shop_benefit2"></div>
            </div>
            <div class="usp">
              <img src="assets/svg/rounded-box-primary.svg" alt="">
              <div [innerHTML]="this.languageService.label.txt_credit_card_shop_benefit3"></div>
            </div>
            <div class="usp">
              <img src="assets/svg/rounded-box-primary.svg" alt="">
              <div [innerHTML]="this.languageService.label.txt_credit_card_shop_benefit4"> </div>
            </div>
            <div class="usp">
              <img src="assets/svg/rounded-box-primary.svg" alt="">
              <div [innerHTML]="this.languageService.label.txt_credit_card_shop_benefit5"> </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="card-payment-right">

      <h1>{{this.languageService.label.web_payment_screen_billing_info}}</h1>

      <div style="text-align: center;" *ngIf="!this.isStripeElementsReady">
        <ion-spinner name="crescent"></ion-spinner>
      </div>


      <form id="payment-form">
        <div id="link-authentication-element">
          <!--Stripe.js injects the Link Authentication Element-->
        </div>
        <div id="payment-element">
          <!--Stripe.js injects the Payment Element-->
        </div>
        <button id="submit">
          <div class="spinner hidden" id="spinner"></div>
          <span id="button-text">
            <ng-container
              *ngIf="!usedTrial&&!purchaseService.skipTrial && !(this.purchaseService.webappSelectedPlan == 'monthly' || this.purchaseService.webappSelectedPlan == 'lifetime')">
              {{this.languageService.label.web_payment_screen_trial_cta}}
            </ng-container>

            <ng-container
              *ngIf="usedTrial || purchaseService.skipTrial || this.purchaseService.webappSelectedPlan == 'monthly' || this.purchaseService.webappSelectedPlan == 'lifetime'">
              {{this.languageService.label.web_payment_screen_no_trial_cta}}
            </ng-container>
          </span>
        </button>
        <div id="payment-message" class="hidden"></div>
      </form>
      <div class="guarentee-container">
        <img id="lock-icon" src="assets/svg/Lock_Icon.svg.svg" width="20px" height="20px" alt="Powered By Stripe">
        <span class="guarentee" [innerHTML]="languageService.label.web_payment_secure_payment"></span>
        <img src="assets/svg/stripe.svg" width="120px" height="28px" alt="Powered By Stripe">
      </div>
      <div class="card-container">
        <img src="assets/svg/cards/visa.svg" width="40px" height="40px" alt="Powered By Stripe">
        <img src="assets/svg/cards/mastercard.svg" width="40px" height="40px" alt="Powered By Stripe">
        <img src="assets/svg/cards/amex.svg" width="40px" height="40px" alt="Powered By Stripe">
        <img src="assets/svg/cards/jcb.svg" width="40px" height="40px" alt="Powered By Stripe">
        <img src="assets/svg/cards/discover.svg" width="40px" height="40px" alt="Powered By Stripe">
        <img src="assets/svg/cards/diners.svg" width="40px" height="40px" alt="Powered By Stripe">
        <img src="assets/svg/cards/unionpay.svg" width="40px" height="40px" alt="Powered By Stripe">
      </div>


    </div>
  </div>

  <div class="terms-conditions">
    <div class="toc-title">{{this.languageService.label.web_payment_screen_title}}</div>
    <div class="toc-text" *ngIf="purchaseService.webappSelectedPlan == 'yearly'" [innerHTML]="labelPriceYearly"></div>
    <div class="toc-text" *ngIf="purchaseService.webappSelectedPlan == 'monthly'" [innerHTML]="labelPriceMonthly"></div>
    <div class="toc-text" *ngIf="purchaseService.webappSelectedPlan == 'lifetime'" [innerHTML]="labelPriceLifetime">
    </div>
  </div>
</ion-content>

<div *ngIf="loginPromoModal" class="overlayFinal"></div>
<div *ngIf="loginPromoModal" id="progressFinal">
  <div class="promoModal">
    <ng-container *ngIf="languageService.target.includes('zh')">
      <h3>別錯過限時優惠！</h3>
      <p>請先登入或註冊會員帳號，以獲得優惠價格</p>

      <div>
        <button (click)="showLoginModal()"> 登入/註冊會員</button>
      </div>

    </ng-container>
    <ng-container *ngIf="!languageService.target.includes('zh')">

      <h3>Get the promotion now!</h3>
      <p>Please log in or create an account to get the promotion.</p>

      <div>
        <button (click)="showLoginModal()">Log in/Sign up</button>
      </div>
    </ng-container>

  </div>
</div>
<div *ngIf="!closePromoModal || !closeSkipPromoModal" class="overlayFinal" (click)="closeModal()"></div>
<div *ngIf="!closeSkipPromoModal" id="progressFinal">
  <div class="promoModal fromWeb">
    <div class="back-btn-modal" (click)="closeModal()">
      <ion-icon name="close"></ion-icon>
    </div>
    <ng-container *ngIf="languageService.target.includes('zh')">
      <h3>放棄優惠?</h3>
      <p>你的特別優惠只有現在有效，錯過就沒了。</p>
      <div class="button-container">

        <div class="btnContainer">
          <button (click)="closeModal()"> 好的，我要結帳</button>
        </div>
        <div class="btnContainer secondary">
          <button (click)="promoBack()"> 不要，我要放棄優惠</button>
        </div>
      </div>

    </ng-container>
    <ng-container *ngIf="!languageService.target.includes('zh')">

      <h3>Skip Discount?</h3>
      <p>This special promotion is only available now.</p>
      <div class="button-container">

        <div class="btnContainer">
          <button (click)="closeModal()"> Ok, I'll take the offer</button>
        </div>
        <div class="btnContainer secondary">
          <button (click)="promoBack()"> No, I don't want the discount.</button>
        </div>
      </div>
    </ng-container>

  </div>
</div>
<div *ngIf="!closePromoModal" id="progressFinal">
  <div class="promoModal">
    <div class="back-btn-modal" (click)="closeModal()">
      <ion-icon name="close"></ion-icon>
    </div>
    <h3 [innerHTML]="languageService.label.web_discount_promocode"></h3>
    <div>
      <div class="inputContainer">

        <input class="promo-input" type="text" placeholder="{{languageService.label.web_discount_code}}"
          [(ngModel)]="couponCode">
        <div class="clearInput" (click)="clearDiscount()">
          <ion-icon name="close"></ion-icon>
        </div>
        <button (click)="applyCouponCode()" [innerHTML]="languageService.label.web_discount_apply_cta"></button>
      </div>
      <div class="checkOut">
        <p [innerHTML]="languageService.label.web_discount_subtotal"></p>
        <p *ngIf="this.purchaseService.webappSelectedPlan == 'monthly'" class="pricesRight">{{webMonthlyPrice}}</p>
        <p *ngIf="this.purchaseService.webappSelectedPlan == 'yearly'" class="pricesRight">{{webYearlyPrice}}</p>
        <p *ngIf="this.purchaseService.webappSelectedPlan == 'lifetime'" class="pricesRight">{{webLifetimePrice}}</p>
        <p *ngIf="couponObject"
          [innerHTML]="languageService.label.web_discount_label + '('+ couponObject?.discount + '%)'"></p>
        <p *ngIf="couponObject" class="pricesRight">- {{ this.discount }}</p>

      </div>

      <hr>
      <div class="checkOut total">
        <p [innerHTML]="languageService.label.web_discount_total"></p>
        <p class="pricesRight">{{ totalPrice }}</p>
      </div>
      <!-- <div class="btnContainer">
        <button (click)="closeModal()" [innerHTML]="languageService.label.web_discount_okay_cta"></button>

      </div> -->
    </div>
  </div>
</div>
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { AuthService } from '@services/auth-service/auth.service';
import { LanguageService } from '@services/language-service/language.service';
import { PurchaseService } from '@services/purchase-service/purchase.service';
import { StorageService } from '@services/storage-service/storage.service';
import { environment } from 'environments/environment';
import { Device } from '@capacitor/core'
import { Platform } from '@ionic/angular';

@Component({
  selector: 'validation-code',
  templateUrl: './validation-code.component.html',
  styleUrls: ['./validation-code.component.scss'],
})
export class ValidationCodeComponent implements OnInit {

  inputElements;
  isPending: boolean = false;
  isSuccess: boolean = false;
  isError: boolean = false;
  isResendError: boolean = false;
  isResendPending: boolean = false;
  isResendDisabled: boolean = false;
  isResendSuccess: boolean = false;
  timer = 59;
  tmp_email;

  @Output('onBack') back = new EventEmitter<boolean>();
  @Output('onVerifySuccess') verifySuccess = new EventEmitter<boolean>();

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    public languageService: LanguageService,
    public purchaseService: PurchaseService,
    private storageService: StorageService,
    public firebaseAnalytics: FirebaseAnalytics,
    private route: ActivatedRoute,
    private platform: Platform
  ) { }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      if (params && params.email) {
        this.tmp_email = params.email;
      }
    });

    this.inputElements = document.querySelectorAll('input.code-input');

    this.inputElements.forEach((ele, index) => {
      ele.addEventListener('keydown', (e) => {
        // if the keycode is backspace & the current field is empty
        // focus the input before the current. Then the event happens
        // which will clear the "before" input box.
        if (e.keyCode === 8 && e.target.value === '') this.inputElements[Math.max(0, index - 1)].focus()
      })
      ele.addEventListener('input', (e) => {
        // take the first character of the input
        // this actually breaks if you input an emoji like 👨‍👩‍👧‍👦....
        // but I'm willing to overlook insane security code practices.
        const [first, ...rest] = e.target.value
        e.target.value = first ?? '' // first will be undefined when backspace was entered, so set the input to ""
        const lastInputBox = index === this.inputElements.length - 1
        const didInsertContent = first !== undefined
        if (didInsertContent && !lastInputBox) {
          // continue to input the rest of the string
          this.inputElements[index + 1].focus()
          this.inputElements[index + 1].value = rest.join('')
          this.inputElements[index + 1].dispatchEvent(new Event('input'))
        }
      })
    })


  }

  async onSubmit(e) {
    e.preventDefault();

    if (!this.authService.user && !this.tmp_email) {
      this.isError = true;
      return
    };
    const code = Array.from(this.inputElements).map((el: any) => el.value).join("");

    if (code.length !== 6) {
      this.isPending = false;
      return;
    }

    this.isPending = true;

    let deviceInfo;

    try {
      deviceInfo = await Device.getInfo();
    } catch (error) {
      console.log("Error getting device info", error);
    }

    this.http.post(`${environment.api}/user/verify`, {
      code: code,
      email: this.authService.user?.email ? this.authService.user?.email : this.tmp_email,
      deviceInfo: deviceInfo,
      native: this.platform.is('cordova')
    }).subscribe(async (res: any) => { // Verify success -> successfully created account
      this.authService.setUser(res.user);
      this.authService.setToken(res.token);
      this.storageService.sendRevenueCatAttributes();
      this.isSuccess = true;
      this.isPending = false;
      this.purchaseService.switchUserAccount().then(() => {
        console.log("User account switched")
      }).catch((err) => {
        console.log("Error switching user account", err)
      })

      this.firebaseAnalytics.setCurrentScreen('screen_onboarding_create_account_thankyou ');

      await this.storageService.storeInitUserDataOnServer(); // Store favs & learned stories
      await this.storageService.importTrainingDataToServer(); // Store training data
      await this.authService.loadUserData(); //reload userdata fetch from server
    }, (err) => {
      this.isError = true;
      this.isPending = false;

      this.inputElements = document.querySelectorAll('input.code-input');

      // loop inputElements and clear all values
      this.inputElements.forEach((ele, index) => {
        ele.value = '';
      })

      // focus first input
      this.inputElements[0].focus();

    })
  }

  goBack() {
    this.back.emit()
  }

  resendVerificationCode() {
    this.isResendPending = true;
    this.http.post(`${environment.api}/user/resend-verification`, {
      email: this.authService.user.email
    }).subscribe((res: any) => {
      this.isResendError = false;
      this.isResendPending = false;
      this.isResendSuccess = true;
    }, (err) => {
      if (err.error.error == 'timeout') {
        this.isResendDisabled = true;
        this.isResendError = false;
        this.isResendSuccess = false;
        this.timerCountdown();
        return;
      }

      this.isResendError = true;
      this.isResendPending = false;
      this.isResendSuccess = false;
    })
  }

  continue() {
    this.verifySuccess.emit(true);
  }

  timerCountdown() {
    if (this.timer > 0) {
      this.timer--;
      setTimeout(() => {
        this.timerCountdown();
      }, 1000);
    } else {
      this.isResendDisabled = false;
      this.timer = 60;
    }
  }
}

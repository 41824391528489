import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { IAPProduct } from '@ionic-native/in-app-purchase-2/ngx';
import { NgZone } from '@angular/core';
import { NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { AuthService } from '../auth-service/auth.service';
import { Appsflyer } from '@ionic-native/appsflyer/ngx';
import { environment } from '../../../environments/environment';
import { LoadingController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { CustomerInfo, LogInResult, Purchases, PurchasesPackage } from "@awesome-cordova-plugins/purchases/ngx";
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { StorageService } from '@services/storage-service/storage.service';
import { LanguageService } from '@services/language-service/language.service';
import { PurchaseOrigin } from 'app/enums/purchase_origin.enum';
import { Product } from '@pages/shop/shop.page';
import { Storage } from '@ionic/storage';

import getSymbolFromCurrency from 'currency-symbol-map';

var getCoutryByAbbreviation = require("country-currency-map").getCountryByAbbreviation;
var getCurrencyAbbreviation = require("country-currency-map").getCurrencyAbbreviation;

export const MONTHLY_PRODUCT_ID = 'com_langster_universal_monthly';
export const MONTHLY_NOW_PRODUCT_ID = 'com_langster_universal_monthly_now';
export const YEARLY_PRODUCT_ID = 'com_langster_universal_yearly';
export const LIFETIME_PRODUCT_ID = 'com.langster.universal.lifetime';

export type ProductIdType = typeof MONTHLY_PRODUCT_ID | typeof MONTHLY_NOW_PRODUCT_ID | typeof YEARLY_PRODUCT_ID | typeof LIFETIME_PRODUCT_ID | 'com_langster_universal_lifetime';

@Injectable({
  providedIn: 'root',
})
export class PurchaseService {
  public user_China = false;
  public product_month: IAPProduct;
  public product_year: IAPProduct;

  public firstPurchase = true;

  public webappSelectedPlan: Product = Product.Yearly;
  public webappSelectedPlan$: Subject<Product> = new Subject<Product>();

  public product_month_price = 'NaN';
  public product_year_price = 'NaN';
  public product_year_price_month = 'NaN';

  public restorationSuccess = false;
  public userPurchaseShop = "";
  public userSubPlan = "";
  public skipTrial = false;

  public webCountryCode = '';
  public userPremium = false;
  public userHasShared = false;
  public sharedUnlock = false;
  public purchaseScreenStatus = false;
  public restoreActive = false;
  public loading = false;
  public buyTriggered = false;
  public buyInfo: any;
  private appsflyerTrialEventName: string;
  public voucherUser = false;
  public showVoucherDialog = false;
  public showVoucherDialogSucess = false;
  public showVoucherDialogError = false;
  public voucherData: any;
  private purchaseOrigin: PurchaseOrigin = PurchaseOrigin.OTHER;

  public serverRegion = 'eu-central-1';
  public bucketName = 'langster-production';

  public userPremiumStatus: Subject<boolean> = new Subject<boolean>();

  private isMigrated = false;

  public webProductYear: StripeProduct;
  public webProductMonthly: StripeProduct;
  public webProductLifetime: StripeProduct;
  public webRevenueCatData;

  public _webProductYear = new BehaviorSubject<StripeProduct>(undefined);
  public _webProductMonthly = new BehaviorSubject<StripeProduct>(undefined);
  public _webProductLifetime = new BehaviorSubject<StripeProduct>(undefined);

  public isNativePurchaseIsReady = false;
  public showLifetimeLabel = false;
  public purchasedSession = false;


  constructor(
    public platform: Platform,
    private ngZone: NgZone,
    private navController: NavController,
    public loadingController: LoadingController,
    private splashScreen: SplashScreen,
    private authService: AuthService,
    private appsflyer: Appsflyer,
    private http: HttpClient,
    private storage: Storage,
    private oneSignal: OneSignal,
    private purchases: Purchases,
    private firebase: FirebaseAnalytics,
    private storageService: StorageService,
    private languageService: LanguageService
  ) {
  }

  public configurePurchasing(): Promise<any> {

    return new Promise(async (resolve, reject) => {

      if (!this.platform.is('cordova')) {
        this.getRevenueCatSubscription();
        this.getUserStripeData();
        // this.getPrices();
        this.getStripeProducts();
        // this.makeUserPremium({}); // ONLY FOR TESTING!!!!
      } else {
        this.purchases.setDebugLogsEnabled(true); // Enable to get debug logs
        this.getUserStripeData();

        let revenueCatAPIKey = this.getRevenueCatKey();

        this.purchases.configureWith({
          apiKey: revenueCatAPIKey,
          appUserID: await this.authService.getUserID()
        });
        this.purchases.setAutomaticAppleSearchAdsAttributionCollection(true);
        this.readTrackingPermission();
        this.firebase.setUserId(await this.authService.getUserID());

        this.purchases.getCustomerInfo().then(data => {
          this.validatePurchaseInfo(data);
          //let device_language = this.languageService.originalDeviceLanguage;
          //let lang = device_language.split("-");
          this.storageService.sendRevenueCatAttributes();
          this.splashScreen.hide();
          navigator.splashscreen.hide();
          this.isNativePurchaseIsReady = true;
          resolve({})
        }).catch(error => {
          this.splashScreen.hide();
          navigator.splashscreen.hide();
          reject(error)
        })

        // get if user's store is in china
        this.purchases.getOfferings().then(async (off) => {
          console.log("offers", JSON.stringify(off))
          console.log("offers current", JSON.stringify(off.current))
          const yearlySub = off.current.availablePackages.find(p => p.packageType == 'ANNUAL');
          if (yearlySub.product.currencyCode == 'CNY' && this.platform.is('ios')) {//CNY
            this.user_China = true;
            let value = await this.storage.get("appStarts");
            let firstSession = false;
            if (!value || value == null || value == 1) firstSession = true;
            setTimeout(() => {
              if (!this.authService.user && !firstSession) {
                this.navController.navigateRoot('/login')
                return
              }
            }, 200);
          }
        }).catch((err) => {
          console.log(err);
        });
      }
    })
  }

  private getRevenueCatKey(): string {
    if (this.platform.is('android')) return environment.revenueCat.androidAPIKey;
    if (this.platform.is('ios')) return environment.revenueCat.appleAPIKey;
    // Support for mac desktop app
    if (this.platform.is('desktop') && this.platform.is('cordova')) return environment.revenueCat.appleAPIKey
  }

  public hasUserShared(): void {
    this.storage.get('userShared').then((val) => {
      if (val == 'true') {
        this.userHasShared = true;
        return true;
      } else {
        this.userHasShared = false;
        return false;
      }
    });
  }

  public hasUserSharedUnlock(): void {
    this.storage.get('userSharedUnlock').then((val) => {
      if (val == 'true') {
        this.sharedUnlock = true;
        return true;
      } else {
        this.sharedUnlock = false;
        return false;
      }
    });
  }

  public markUserAsShared(): void {
    this.storage.set('userShared', 'true');
  }

  public markUserAsSharedUnlock(): void {
    this.storage.set('userSharedUnlock', 'true');
    this.sharedUnlock = true;
  }

  public async switchUserAccount(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.purchases.logIn(await this.authService.getUserID()).then(async (data: LogInResult) => {
        this.validatePurchaseInfo(data.customerInfo);
        let device_language = this.languageService.originalDeviceLanguage;
        let lang = device_language.split("-");
        if (this.isNativePurchaseIsReady) {
          this.purchases.setAttributes({
            "level": this.storageService.userLevel,
            "learning_language": this.languageService.origin,
            "device_language": lang[0],
            "store_locale": this.languageService.origin,
            "$mparticleId": lang[0],
            "$mixpanelDistinctId": this.languageService.origin
          })
        }
        this.firebase.setUserId(await this.authService.getUserID());

        if (!this.platform.is('cordova')) {
          this.getRevenueCatSubscription();
        }

        if (this.platform.is('ios')) {
          resolve(await this.purchases.restorePurchases());
        }

        if (this.platform.is('android')) {
          await this.purchases.restorePurchases()
          this.purchases.getCustomerInfo().then(data => {
            this.validatePurchaseInfo(data);
            resolve({})
          })
        }
      }).catch(error => {
        console.error("Error switching user account", error);
        reject(error);
      })
    })
  }

  public logoutUserAccount() {
    this.purchases.logOut().then(() => {
      console.log("User logged out");
    }).catch(error => {
      console.error("Error logging out user", error);
    });
  }

  public purchasePackage(pack: PurchasesPackage, showPurchaseSuccessPage = false): Promise<any> {
    console.log("DEBUG_PURCHASE_0");
    return new Promise((resolve, reject) => {

      if (!this.platform.is('cordova')) {
        return reject('Not a cordova platform');
      }

      this.showLoader();

      if (pack.packageType == 'MONTHLY' && environment.single) this.appsflyerTrialEventName = 'trial_monthly';
      if (pack.packageType == 'CUSTOM') this.appsflyerTrialEventName = 'trial_monthly';
      if (pack.packageType == 'ANNUAL') this.appsflyerTrialEventName = 'trial_yearly';
      if (pack.packageType == 'LIFETIME') this.appsflyerTrialEventName = 'trial_lifetime';

      this.purchases.purchasePackage(pack).then((res) => {
        const valid = this.validatePurchaseInfo(res.customerInfo);
        this.loadingDismiss();
        if (valid) {
          this.succesfullPurchase(showPurchaseSuccessPage);
          return resolve(true)
        }
      }).catch(error => {
        this.loadingDismiss();
        return reject(error)
      })
    });
  }

  public async restorePurchase(showLoader = true) {
    const loading = await this.loadingController.create({
      spinner: "lines-small",
      duration: 5000,
      message: this.languageService.label.txt_restore_purchase_loader,
      translucent: true,
      cssClass: 'custom-class custom-loading',
      backdropDismiss: false,
    });
    if (showLoader) await loading.present();
    return new Promise((resolve, reject) => {
      this.purchases.restorePurchases().then(data => {
        this.restorationSuccess = this.validatePurchaseInfo(data);
        resolve('success')
      }).catch(error => {
        this.restorationSuccess = false;
        console.log("Error restoring...", error);
      })
    })
  }

  private validatePurchaseInfo(data: CustomerInfo) {
    const status = typeof data.entitlements.active.Premium !== "undefined";
    if (status) {
      this.makeUserPremium({});
      return true;
    } else {
      return false;
    }
  }

  public makeUserPremium(product): void {
    if (this.userPremium == true) return
    this.ngZone.run(() => {
      this.userPremium = true;
      this.oneSignal.sendTag("premium", "true");
      this.firebase.setUserProperty("premium", "true");
    });
  }

  public makeUserFree(): void {
    if (this.userPremium == false) return
    this.ngZone.run(() => {
      this.userPremium = false;
      this.oneSignal.sendTag("premium", "false");
      this.firebase.setUserProperty("premium", "false");
    });
  }

  public async checkPremium(userId: string, updatePremiumInApp = false) {
    return new Promise((resolve) => {
      this.http.get<any>(`${environment.api}/user/subscription/${userId}`, {
        headers: this.authService.authHeader
      }).subscribe((res) => {
        if (res.region) {
          this.serverRegion = res.region;
          if (res.region == 'ap-northeast-1') this.bucketName = 'langster-production-tokio';
          if (res.region == 'us-east-2') this.bucketName = 'langster-production-ohio';
        }
        if (updatePremiumInApp && res.subscribed) {
          this.ngZone.run(() => {
            this.voucherUser = true;
            this.userPremium = true;
            this.oneSignal.sendTag("premium", "true");
            this.firebase.setUserProperty("premium", "true");
            if (res.voucher) this.voucherData = res.voucher;
          });
          resolve(true);
        } else {
          this.oneSignal.sendTag("premium", "false");
          this.firebase.setUserProperty("premium", "false");
          resolve(false);
        }
      }, (err) => {
        console.error(err);
      });
    });
  }

  succesfullPurchase(showPurchaseSuccessPage: boolean = true): void {
    this.ngZone.run(() => {
      if (showPurchaseSuccessPage) this.navController.navigateForward('purchase');
      this.storeUsersPurchase();
      this.sendAppsflyerEventPurchase();
    });
  }

  public showLoader() {
    this.ngZone.run(() => {
      this.loading = true;
    });
  }

  public loadingDismiss() {
    this.ngZone.run(() => {
      this.loading = false;
    });
  }

  storeUsersPurchase() {
    if (this.buyTriggered) return;
    this.buyTriggered = true;
  }

  sendAppsflyerEventPurchase(): void {
    this.appsflyer.logEvent(this.appsflyerTrialEventName, {});
  }

  checkVoucherValid(): void {
    if (!this.voucherData) return;
    const now = new Date();
    const expiryAt = new Date(this.voucherData.expiryAt);
    if (now > expiryAt) {
      this.userPremium = false;
      this.oneSignal.sendTag("premium", "true");
    }
  }

  revenueMigration() {
    if (this.isMigrated) return;
    let isSubscribedInOldSystem = true;
    let isSubscribedInRevenueCat = false;

    this.purchases.getCustomerInfo().then(data => {
      isSubscribedInRevenueCat = typeof data.entitlements.active != undefined;
      if (isSubscribedInOldSystem && !isSubscribedInRevenueCat) {
        this.purchases.syncPurchases();
        this.isMigrated = true;
      }
    })
  }

  getPrices() {
    this.http.get(`${environment.api}/payments/prices`).subscribe((res: any) => {
      this.webProductYear = res.find(el => el.recurring?.interval == 'year')
      this.webProductMonthly = res.find(el => el.recurring?.interval == 'month')
      this.webProductLifetime = res.find(el => el.nickname == 'Lifetime')
    })
  }

  async getStripeProducts() {
    this.http.get(`${environment.api}/payments/products`).subscribe((res: any) => {
      this.webProductYear = res.find(el => el.id == 'com_langster_universal_yearly')
      this.webProductMonthly = res.find(el => el.id == 'com_langster_universal_monthly')
      this.webProductLifetime = res.find(el => el.id == 'com_langster_universal_lifetime')
      this._webProductYear.next(this.webProductYear);
      this._webProductYear.complete();
    })
  }

  async getRevenueCatSubscription() {
    if (!this.authService.user) return;
    // If a user purchased the app in ios/android-store, we need to ask revenueCat for the data and validate it
    // as the Ionic Purchases Plugin only works for native -> manual request to revenueCatApi 
    // the same way as in the native versions of langster
    this.http.get(`https://api.revenuecat.com/v1/subscribers/${await this.authService.getUserID()}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${environment.revenueCat.appleAPIKey}`,
      }
    }).subscribe((res: any) => {
      // check if user is subscribed for each product
      // Product: com_langster_universal_monthly
      //get the purchase method 
      if (res.subscriber.entitlements.Premium) {
        const entitlement = res.subscriber.entitlements.Premium;
        const sub = entitlement.product_identifier
        if (sub == "com.langster.universal.lifetime") {
          if (res.subscriber.non_subscriptions[sub][0].store.toLocaleLowerCase() == "app_store" || res.subscriber.non_subscriptions[sub][0].store.toLocaleLowerCase() == "mac_app_store") this.userPurchaseShop = 'Apple'
          else if (res.subscriber.non_subscriptions[sub][0].store.toLocaleLowerCase() == "play_store") this.userPurchaseShop = 'Google'
        } else {
          if (res.subscriber.subscriptions[sub].store.toLocaleLowerCase() == "app_store" || res.subscriber.subscriptions[sub].store.toLocaleLowerCase() == "mac_app_store") this.userPurchaseShop = 'Apple'
          else if (res.subscriber.subscriptions[sub].store.toLocaleLowerCase() == "play_store") this.userPurchaseShop = 'Google'
        }
      }

      if (res.subscriber.subscriptions[MONTHLY_PRODUCT_ID]) {
        const subscription = res.subscriber.subscriptions[MONTHLY_PRODUCT_ID];
        if (new Date(subscription.expires_date) > new Date()) {
          this.userSubPlan = "Monthly Plan"
          this.makeUserPremium({});
        }
      }

      if (res.subscriber.subscriptions[MONTHLY_NOW_PRODUCT_ID]) {
        const subscription = res.subscriber.subscriptions[MONTHLY_NOW_PRODUCT_ID];
        if (new Date(subscription.expires_date) > new Date()) {
          this.userSubPlan = "Monthly Plan"
          this.makeUserPremium({});
        }
      }


      // Product: com_langster_universal_yearly
      if (res.subscriber.subscriptions[YEARLY_PRODUCT_ID]) {
        const subscription = res.subscriber.subscriptions[YEARLY_PRODUCT_ID];
        if (new Date(subscription.expires_date) > new Date()) {
          this.userSubPlan = "Yearly Plan"
          this.makeUserPremium({});
        }
      }

      if (res.subscriber.other_purchases[LIFETIME_PRODUCT_ID]) {
        this.userSubPlan = "Lifetime Plan"
        this.makeUserPremium({});
      }

      // Check Entintlements
      if (res.subscriber.entitlements.Premium) {
        const entitlement = res.subscriber.entitlements.Premium;
        if (new Date(entitlement.expires_date) > new Date()) {
          this.makeUserPremium({});
        }
      }

      // Product: com_langster_universal_lifetime // not yet implemented
      this.webRevenueCatData = res;
    }, (error) => {
      console.log(error)
    })
  }

  async getUserStripeData() {
    if (!this.authService.user) return;
    // We also need to check the userdata for the stripe subscription status that is safed in the users data
    // the placement of the method must be adjusted when users data is also stored in our internal DB
    // -> move to authService
    // subscriptionStats == 'active' or 'trailing' indicates a user with an active subscription
    this.authService.loadUserFromServer(this.authService.user._id).then((userData: any) => {
      if (userData?.stripeSubscriptionStatus == 'active' || userData?.stripeSubscriptionStatus == 'trialing' || userData?.stripeLifetimePurchase) {
        this.makeUserPremium({})
      }
    }).catch(error => {
      console.error("Error loading Stripe data", error);
    })
  }

  checkStripeSubscription(user) {
    if (user.stripeSubscriptionStatus == 'active' || user.stripeSubscriptionStatus == 'trialing' || user?.stripeLifetimePurchase) {
      this.makeUserPremium({})
    }
  }

  async getStripeSubscription() {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.api}/payments/subscription`, { headers: this.authService.authHeader }).subscribe((res: any) => {
        resolve(res);
      }, (error) => {
        reject("Error loading Stripe Subscription data")
      });
    })
  }

  async getStripeCustomer() {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.api}/payments/customer`, { headers: this.authService.authHeader }).subscribe((res: any) => {
        resolve(res);
      }, (error) => {
        reject("Error loading Stripe Customer data")
      });
    })
  }

  async getStripePaymentMethod(pmId: string) {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.api}/payments/customer/payment-method/${pmId}`, { headers: this.authService.authHeader }).subscribe((res: any) => {
        resolve(res);
      }, (error) => {
        reject("Error loading Stripe Payment Methods data")
      });
    })
  }

  async cancelStripeSubscription() {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.api}/payments/cancel-subscription`, {}, { headers: this.authService.authHeader }).subscribe((res: any) => {
        resolve(res);
      }, (error) => {
        reject("Error cancelling Stripe Subscription")
      });
    })
  }

  async resumeStripeSubscription() {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.api}/payments/resume-subscription`, {}, { headers: this.authService.authHeader }).subscribe((res: any) => {
        resolve(res);
      }, (error) => {
        reject("Error resuming Stripe Subscription")
      });
    })
  }

  async changeStripePaymentMethod() {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.api}/payments/change-payment-method`, {}, { headers: this.authService.authHeader }).subscribe((res: any) => {
        resolve(res);
      }, (error) => {
        reject("Error changing Stripe Payment Method")
      });
    })
  }

  redirectToCheckout(sessionId: string) {
    const stripe = Stripe(environment.stripe.key);
    stripe.redirectToCheckout({
      sessionId: sessionId
    })
  }

  changeWebAppPlan(product: Product) {
    this.webappSelectedPlan = product;
    this.webappSelectedPlan$.next(this.webappSelectedPlan);
  }

  async setRevenuecatAppsflyerAttributes() {
    this.purchases.setAppsflyerID(await this.appsflyer.getAppsFlyerUID());
    this.purchases.collectDeviceIdentifiers();
    if (this.isNativePurchaseIsReady) this.purchases.setAttributes({ $ip: "" });
  }

  public setPurchaseOrigin(origin: PurchaseOrigin) {
    this.purchaseOrigin = origin;
  }

  public getCountryIP() {
    this.http.get(`https://ipinfo.io?token=${environment.ipInfoToken}`).subscribe((data) => {
      let ipInfo = JSON.parse(JSON.stringify(data));
      console.log('ipInfo', ipInfo.country);
      this.webCountryCode = ipInfo.country;
    });
  }

  public getPurchaseOrigin(): PurchaseOrigin {
    return this.purchaseOrigin;
  }

  public getUserCurrency() {
    const code = this.getUserIPCurrency();
    // console.log("code", code);
    if (code) return code;

    let countryCode = navigator.language.slice(-2).toUpperCase();
    if (countryCode == "GB" || navigator.language == "en-GB-oxendict") return "GBP";
    if (countryCode == "DE") return "EUR";
    if (countryCode == "CN") return "CNY";
    if (countryCode == "JP" || countryCode == "JA") return "JPY";
    if (countryCode == "KR") return "KRW";
    if (countryCode == "ES") return "EUR";
    if (countryCode == "UA" || countryCode == "UK") return "UAH";
    if (countryCode == "FR") return "EUR";
    if (countryCode == "IT") return "EUR";
    if (countryCode == "RU") return "RUB";
    if (countryCode == "PL") return "PLN";
    if (countryCode == "TR") return "TRY";
    if (countryCode == "KO") return "KRW";
    if (countryCode == "ZH") return "CNY";

    const c = getCurrencyAbbreviation(getCoutryByAbbreviation(countryCode));
    if (!c) return 'USD';
    return c;
  }

  public getUserIPCurrency() {
    let countryCode;
    if (this.webCountryCode) countryCode = this.webCountryCode;

    if (environment.api.includes('production')) {
      if (countryCode == "GB" || countryCode == "IO" || countryCode == "FK" || countryCode == "GI" || countryCode == "GG" || countryCode == "IM" || countryCode == "JE") return "GBP";
      if (countryCode == "DE" || countryCode == "FR" || countryCode == "ES" || countryCode == "HR" || countryCode == "AT" || countryCode == "BE" || countryCode == "CY" || countryCode == "EE" || countryCode == "FI" || countryCode == "IE" || countryCode == "GR" || countryCode == "LV" || countryCode == "LU" || countryCode == "LT" || countryCode == "MT" || countryCode == "NL" || countryCode == "PT" || countryCode == "SK" || countryCode == "SI") return "EUR";
      if (countryCode == "CN") return "CNY";
      if (countryCode == "US") return "USD";
      if (countryCode == "CH") return "CHF";
      if (countryCode == "AE") return "AED";
      if (countryCode == "JP") return "JPY";
      if (countryCode == "KR") return "KRW";
      if (countryCode == "UA") return "UAH";
      if (countryCode == "RU") return "RUB";
      if (countryCode == "PL") return "PLN";
      if (countryCode == "TR") return "TRY";
      if (countryCode == "KO") return "KRW";
      if (countryCode == "ZH") return "CNY";
      if (countryCode == "TW") return "TWD";
      if (countryCode == "AF") return "AFN";
      if (countryCode == "AM") return "AMD";
      if (countryCode == "AL") return "ALL";
      if (countryCode == "NL") return "ANG";
      if (countryCode == "CL") return "CLP";
      if (countryCode == "AO") return "AOA";
      if (countryCode == "AR") return "ARS";
      if (countryCode == "AU") return "AUD";
      if (countryCode == "AW") return "AWG";
      if (countryCode == "AZ") return "AZN";
      if (countryCode == "BA") return "BAM";
      if (countryCode == "BB") return "BBD";
      if (countryCode == "BD") return "BDT";
      if (countryCode == "BG") return "BGN";
      if (countryCode == "BI") return "BIF";
      if (countryCode == "BS") return "BSD";
      if (countryCode == "BZ") return "BZD";
      if (countryCode == "BM") return "BMD";
      if (countryCode == "BN") return "BND";
      if (countryCode == "BW") return "BWP";
      if (countryCode == "BO") return "BOB";
      if (countryCode == "BR") return "BRL";
      if (countryCode == "BY") return "BYN";
      if (countryCode == "CA") return "CAD";
      if (countryCode == "CD") return "CDF";
      if (countryCode == "DK") return "DKK";
      if (countryCode == "HR") return "HRK";
      if (countryCode == "HU") return "HUF";
      if (countryCode == "IN") return "INR";
      if (countryCode == "IS") return "ISK";
      if (countryCode == "SA") return "SAR";
      if (countryCode == "SE") return "SEK";
      if (countryCode == "NZ") return "NZD";
      if (countryCode == "SG") return "SGD";
      if (countryCode == "ID") return "IDR";
      if (countryCode == "EG") return "EGP";
      if (countryCode == "KE") return "KES";
      if (countryCode == "CO") return "COP";
      if (countryCode == "CR") return "CRC";
      if (countryCode == "CV") return "CVE";
      if (countryCode == "CZ") return "CZK";
      if (countryCode == "DJ") return "DJF";
      if (countryCode == "DZ") return "DZD";
      if (countryCode == "DO") return "DOP";
      if (countryCode == "ET") return "ETP";
      if (countryCode == "FJ") return "FJD";
      if (countryCode == "VN") return "VND";
      if (countryCode == "TH") return "THB";

      if (countryCode == "FJ") return "FKP";
      if (countryCode == "GE") return "GEL";
      if (countryCode == "GI") return "GIP";
      if (countryCode == "GM") return "GMD";
      if (countryCode == "GN") return "GNF";
      if (countryCode == "GT") return "GTQ";
      if (countryCode == "HK") return "HKD";
      if (countryCode == "GY") return "GYD";
      if (countryCode == "FJ") return "HTG";
      if (countryCode == "IL") return "ILS";
      if (countryCode == "JM") return "JMD";
      if (countryCode == "KG") return "KGS";
      if (countryCode == "KH") return "KHR";
      if (countryCode == "KM") return "KMF";
      if (countryCode == "KY") return "KYD";
      if (countryCode == "KZ") return "KZT";
      if (countryCode == "LA") return "LAK";
      if (countryCode == "LK") return "LKR";
      if (countryCode == "LR") return "LRD";
      if (countryCode == "LS") return "LSL";
      if (countryCode == "MA") return "MAD";
      if (countryCode == "MX") return "MXN";
      if (countryCode == "MY") return "MYR";
      if (countryCode == "NG") return "NGN";
      if (countryCode == "NO") return "NOK";
      if (countryCode == "PE") return "PEN";
      if (countryCode == "PH") return "PHP";
      if (countryCode == "PK") return "PKR";
      if (countryCode == "QA") return "QAR";
      if (countryCode == "RO") return "RON";
      if (countryCode == "RS") return "RSD";
      if (countryCode == "SZ") return "SZL";
      if (countryCode == "ZA") return "ZAR";
    } else {
      if (countryCode == "DE" || countryCode == "FR" || countryCode == "ES" || countryCode == "HR" || countryCode == "AT" || countryCode == "BE" || countryCode == "CY" || countryCode == "EE" || countryCode == "FI" || countryCode == "IE" || countryCode == "GR" || countryCode == "LV" || countryCode == "LU" || countryCode == "LT" || countryCode == "MT" || countryCode == "NL" || countryCode == "PT" || countryCode == "SK" || countryCode == "SI") return "EUR";
      if (countryCode == "CN") return "CNY";
      if (countryCode == "ZH") return "CNY";
      if (countryCode == "US") return "USD";
      if (countryCode == "CH") return "CHF";
      if (countryCode == "JP") return "JPY";
      if (countryCode == "KR") return "KRW";
      if (countryCode == "TW") return "TWD";
    }

    return null;
  }

  public getCurrencySymbolFromCurrencyCode(currencyCode: string) {
    switch (currencyCode) {
      case 'NGN':
        return '₦';
      case 'AUD':
        return 'AU$';
      case 'AZN':
        return '₼';
      case 'IDR':
        return 'Rp';
      case 'CAD':
        return 'CA$';
      case 'CNY':
        return 'CN¥';
      case 'HKD':
        return 'HK$';
      case 'GBP':
        return '£';
      case 'INR':
        return '₹';
      case 'EUR':
        return '€';
      case 'EGP':
        return 'E£';
      case 'JPY':
        return '¥';
      case 'MYR':
        return 'RM';
      case 'NZD':
        return 'NZ$';
      case 'KRW':
        return '₩';
      case 'RUB':
        return '₽';
      case 'MXN':
        return 'Mex$';
      case 'THB':
        return '฿';
      case 'TWD':
        return 'NT$';
      case 'VND':
        return '₫';
      case 'TRY':
        return '₺';
      case 'SGD':
        return 'S$';
      case 'SEK':
        return 'kr';
      case 'SAR':
        return '﷼';
      case 'BRL':
        return 'R$';
      case 'ZAR':
        return 'R';
      default:
        return getSymbolFromCurrency(currencyCode);
    }
  }

  get _webProductYear$(): Observable<StripeProduct> {
    return this._webProductYear.asObservable();
  }

  get _webProductMonthly$(): Observable<StripeProduct> {
    return this._webProductMonthly.asObservable();
  }

  get _webProductLifetime$(): Observable<StripeProduct> {
    return this._webProductLifetime.asObservable();
  }

  readTrackingPermission() {

    if (window.cordova) {
      window.cordova.exec(win, fail, 'idfa', "getInfo", []);
    }

    function win(res) {
      console.log('success  ' + JSON.stringify(res));
      this.purchases.enableAdServicesAttributionTokenCollection()
    }
    function fail(res) {
      console.log('fail ' + JSON.stringify(res));
    }
  }
}
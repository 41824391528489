<app-header [trainingInfo]="true" [separetedTitle]="true" title="{{this.languageService.label.txt_training_page_title}}"></app-header>
<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col class="container" style="z-index: 1000;">
        <div class="word-categories">
          <div class="training-container training-container-big easy" (click)="showOverviewEasy()">
              <span class="training-label">{{this.languageService.label.txt_training_page_easy_title}}</span>
              <span class="training-amount">{{countEasy}} {{this.languageService.label.txt_profile_label_words}}</span>
              <ion-icon name="chevron-forward-outline"></ion-icon>
          </div>
          <div class="training-container training-container-big okay" (click)="showOverviewOkay()">
              <span class="training-label">{{this.languageService.label.txt_training_page_good_title}}</span>
              <span class="training-amount">{{countGood}} {{this.languageService.label.txt_profile_label_words}}</span>
              <ion-icon name="chevron-forward-outline"></ion-icon>
          </div>
          <div class="training-container training-container-big hard" (click)="showOverviewHard()">
              <span class="training-label">{{this.languageService.label.txt_training_page_hard_title}}</span>
              <span class="training-amount">{{countHard}} {{this.languageService.label.txt_profile_label_words}}</span>
              <ion-icon name="chevron-forward-outline"></ion-icon>
          </div>
          <div class="training-container today-done-container">
            <div class="today-container" (click)="goToExercise()">
              <ion-icon class="action-icon" name="calendar-clear-outline"></ion-icon>
              <span>{{this.languageService.label.txt_training_page_today_title}}</span>
              <span>{{countToday}}</span>
            </div>
            <div class="done-container" (click)="showOverviewDone()">
              <ion-icon class="action-icon" name="checkmark-outline"></ion-icon>
              <span>{{this.languageService.label.txt_training_page_done_title}}</span>
              <span>{{countDone}}</span>
            </div>
          </div>
          <button class="review-now-btn" (click)="goToExercise()">
            <ng-container *ngIf="countToday > 0">{{this.languageService.label.txt_training_page_review_btn}}</ng-container>
            <ng-container *ngIf="0 >= countToday">{{this.languageService.label.txt_training_page_review_btn_no_words}}</ng-container>
          </button>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<div *ngIf="askClear"  class="overlayFinal"></div>

import { Component, Input, OnInit } from '@angular/core';
import WordSetting, { WordSettingTense } from '@interfaces/word-setting';
import { ModalController } from '@ionic/angular';
import { WordService } from '@services/word-service/word.service';
import { LanguageService } from '../../service/language-service/language.service';
import { AudioServiceService } from '@services/audio-service/audio-service.service';

@Component({
  selector: 'app-word-detail',
  templateUrl: './word-detail.page.html',
  styleUrls: ['./word-detail.page.scss'],
})
export class WordDetailPage implements OnInit {

  @Input() data: any;
  word: any;
  isAudioActive: boolean;
  public audio = new Audio();
  personDe = ['ich', 'du', 'er/sie/es', 'wir', 'ihr', 'sie'];
  suffix: any;
  newFormGroup = [
    'plain',
    'polite',
    'other'
  ];

  public plainForms: any[] = []
  public politeForms: any[] = [];
  public otherForms: any[] = [];
  public adjPlainForm: any[] = []
  public pastForm: any[] = []
  public subjunctiveForm: any[] = [];
  public attributiveForm: any[] = [];
  public negativeForm: any[] = [];
  public wordTensesIndicative: any[];
  public wordTensesSubjunctive: any[];
  public indicative: boolean = false;
  public subjunctive: boolean = false;

  constructor(
    public languageService: LanguageService,
    public audioService: AudioServiceService,
    public modalController: ModalController,
    public wordService: WordService
  ) { }

  /**
   * Uses the loaded word-settings from the backend to sort the tense array of 
   * the input word-data. Replaces name of the original tense with the translation 
   * for the target-language (saved ink the language-service). Name will only be replaced
   * if a translation is available, otherwise the original name will be kept.
   * An display property will be added to the tenses of the word. 
   */
  ngOnInit(): void {
    this.init();
  }

  init() {
    this.word = JSON.parse(JSON.stringify(this.data));
    console.log(this.word)

    if (this.word.language == "de") {
      this.suffix = this.getForm('prefix');
      if (this.suffix) this.suffix = this.suffix.text;
      const p1 = this.word.forms.find(el => el.type == 'participle_1')
      const p2 = this.word.forms.find(el => el.type == 'participle_2')

      if (p1 || p2) {
        // remove object from tense array
        //this.word.tenses.splice(this.word.tenses.findIndex(el => el.name == 'participle'), 1);

        // add new tense object
        const participle = {
          tense: 'participle',
          forms: []
        }

        if (p1.text) participle.forms.push({ text: p1.text });
        if (p2.text) participle.forms.push({ text: p2.text });

        this.word.tenses.push(participle);
      }
    }

    if (this.languageService.origin == 'es') {
      for (let z = 0; z < this.word.tenses.length; z++) {
        if (this.word.tenses[z].mode == 'indicative') {
          this.indicative = true;
        } else if (this.word.tenses[z].mode == 'conjunctive' || this.word.tenses[z].mode == 'subjunctive') {
          this.subjunctive = true;
        }
      }
      if (this.indicative) this.wordTensesIndicative = this.populateESTense('indicative')
      if (this.subjunctive) this.wordTensesSubjunctive = this.populateESTense('conjunctive')
      this.word.tenses = this.populateESTense('none')
    } else {
      const newTenses = [];
      const setting: WordSetting = this.wordService.wordSetting;
      for (let index = 0; index < setting.tenses.length; index++) {
        const tense: WordSettingTense = setting.tenses[index];
        let d: any = this.getTense(tense.name, 'none');
        if (!d) {
          d = this.getForm(tense.name);
          if (d) d = {
            tense: this.getTranslation(tense),
            forms: [
              {
                text: d.text
              }
            ]
          }
        }

        if (d) {
          if (tense.display) d.display = true;
          else d.display = false;

          const translation = this.getTranslation(tense);
          if (translation != '') d.tense = translation;

          if (this.languageService.origin == 'de') {
            if (tense.name == 'present tense' || tense.name == 'past tense' || tense.name == 'perfect tense') {
              for (let index = 0; index < d.forms?.length; index++) {
                const form = d.forms[index];
                if (!form.persona || form.persona == '') form.persona = this.personDe[index];
                if (tense.name == 'perfect tense' && this.word.language == 'de') form.persona = '';
              }
            }
          }

          if (!this.isTenseDataEmpty(d)) newTenses.push(d);
        } else {
          if (tense.name === "Auxiliary") {
            d = {
              display: true,
              tense: this.getTranslation(tense),
              forms: [
                { text: this.getAuxiliary() }
              ],
            };
            if (this.getAuxiliary() != '') newTenses.push(d);
          }
        }
      }
      this.word.tenses = newTenses;
    }
    if (this.languageService.origin == 'ja') this.getFormGroup()
  }

  populateESTense(mode: string) {
    let newTenses = [];
    const setting: WordSetting = this.wordService.wordSetting;
    for (let index = 0; index < setting.tenses.length; index++) {
      const tense: WordSettingTense = setting.tenses[index];
      let d: any = this.getTense(tense.name, mode);
      if (!d && mode == 'none') {
        d = this.getForm(tense.name);
        if (d) d = {
          tense: this.getTranslation(tense),
          forms: [
            {
              text: d.text
            }
          ]
        }
      }

      if (d) {
        if (tense.display) d.display = true;
        else d.display = false;

        const translation = this.getTranslation(tense);
        if (translation != '') d.tense = translation;

        if (!this.isTenseDataEmpty(d)) newTenses.push(d);

      }
      console.log(d)
    }
    console.log(newTenses)
    return newTenses;
  }

  isTenseDataEmpty(tense: any): boolean {
    if (!tense.forms) return true;
    for (let index = 0; index < tense.forms.length; index++) {
      const form = tense.forms[index];
      if (form.text != "") return false;
    }

    return true;
  }

  getTense(name: string, mode: string) {
    // Search tenses for hit
    for (let index = 0; index < this.word.tenses.length; index++) {
      const tense = this.word.tenses[index];
      if ((tense.tense === name || tense.tense === name.toLowerCase()) && tense.mode == 'indicative' && mode == 'indicative') {
        return tense;
      } else if ((tense.tense === name || tense.tense === name.toLowerCase()) && (tense.mode == 'conjunctive' || tense.mode == 'subjunctive') && (mode == 'conjunctive' || mode == 'subjunctive')) {
        return tense;
      } else if ((tense.tense === name || tense.tense === name.toLowerCase()) && mode == 'none' && !(tense.mode == 'conjunctive' || tense.mode == 'subjunctive' || tense.mode == 'indicative')) {
        return tense;
      } else if ((tense.tense.toLowerCase().includes(name.toLowerCase())) && (tense.mode == 'conjunctive' || tense.mode == 'subjunctive') && (mode == 'conjunctive' || mode == 'subjunctive')) {
        console.log("match", tense)
        return tense;
      }
    }

    // if nothing found in tenses search object keys for possible hit 
    // and create tense object and return it
    for (const key in this.word) {
      if (key === name.toLowerCase()) {
        return {
          tense: name,
          forms: [
            {
              text: this.word[key]
            }
          ]
        }
      }
    }
  }

  getFormGroup() {
    this.plainForms = [];
    this.politeForms = [];
    this.otherForms = [];
    this.adjPlainForm = []
    this.pastForm = []
    this.subjunctiveForm = [];
    this.attributiveForm = [];
    this.negativeForm = [];
    // Search tenses for hit
    for (let index = 0; index < this.word.forms.length; index++) {
      if (this.word.forms[index].formGroupName == 'plain' && (this.word.forms[index].kanji && this.word.forms[index].kanji != 'n/a')) {
        this.plainForms.push(this.word.forms[index]);
      }
      else if (this.word.forms[index].formGroupName == 'polite' && (this.word.forms[index].kanji && this.word.forms[index].kanji != 'n/a')) {
        this.politeForms.push(this.word.forms[index]);
      }
      else if (this.word.forms[index].formGroupName == 'other' && (this.word.forms[index].kanji && this.word.forms[index].kanji != 'n/a')) {
        this.otherForms.push(this.word.forms[index]);
      }
      else if (this.word.forms[index].type == "plain form" && (this.word.forms[index].kanji && this.word.forms[index].kanji != 'n/a')) {
        this.adjPlainForm.push(this.word.forms[index]);
      }
      else if (this.word.forms[index].type == 'past tense' && (this.word.forms[index].kanji && this.word.forms[index].kanji != 'n/a')) {
        if (this.pastForm[0] && this.pastForm[0].kanji == this.word.forms[index].kanji) continue;
        this.pastForm.push(this.word.forms[index]);
      }
      else if (this.word.forms[index].type == 'subjunctive form' && (this.word.forms[index].kanji && this.word.forms[index].kanji != 'n/a')) {
        this.subjunctiveForm.push(this.word.forms[index]);
      }
      else if (this.word.forms[index].type == "attributive form" && (this.word.forms[index].kanji && this.word.forms[index].kanji != 'n/a')) {
        this.attributiveForm.push(this.word.forms[index]);
      }
      else if (this.word.forms[index].type == 'negative form' && (this.word.forms[index].kanji && this.word.forms[index].kanji != 'n/a')) {
        this.negativeForm.push(this.word.forms[index]);
      }
      else if (this.word.forms[index].formGroupName == 'adjective' && (this.word.forms[index].kanji && this.word.forms[index].kanji != 'n/a')) {
        this.otherForms.push(this.word.forms[index]);
      }
    }
  }

  getForm(name: string) {
    let c = this.word.forms.find(el => el.type == name);
    if (!c) c = this.word.forms.find(el => el.type == name.toLowerCase());
    return c;
  }

  getTranslation(tense: WordSettingTense): string {
    for (let index = 0; index < tense.translations.length; index++) {
      const element = tense.translations[index];
      let lang = this.languageService.target;
      if (lang === 'en') lang = 'eng';
      if (element.language == lang) {
        return element.text;
      }
    }
    return '';
  }

  getAuxiliary(): string {
    if (this.word.language == 'fr') {
      const aux: string = this.getTense('Perfect tense', 'none')?.forms[0].aux;
      if (aux === 'ai') return 'avoir';
      if (aux === 'suis') return 'être';
    }

    if (this.word.language == 'en') {
      const aux = this.word.tenses.find(tense => {
        const tense_name = tense.tense.split(" ")[0] + " " + tense.tense.split(" ")[1];
        return tense_name === 'present perfect'
      }).forms[0]?.aux;
      if (aux === 'am') return 'to be';
      if (aux === 'have') return 'to have';
    }

    return '';
  }

  getGerundio(): string {
    let x = this.getForm('gerundio')?.text;
    return x;
  }

  getParticipio(): string {
    return this.getForm('participio')?.text;
  }

  closeModal() {
    this.modalController.dismiss();
  }

  playAudio(): void {
    this.audioService.current_audio.pause();
    this.audioService.initNewAudio(this.word.audiofile, true);

    this.isAudioActive = true;
    setTimeout(() => {
      this.isAudioActive = false;
    }, 2000);
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { Grammar } from '../../interfaces/story';
import { LanguageService } from '../../service/language-service/language.service';
import { WordService } from './../../service/word-service/word.service';
import { UtilsService } from '../../service/utils-service/utils.service';
import { ScreensizeService } from '@services/screensize-service/screensize.service';

@Component({
  selector: 'grammar',
  templateUrl: './grammar.component.html',
  styleUrls: ['./grammar.component.scss'],
})
export class GrammarComponent implements OnInit {

  name = '';
  description = '';
  contentLong: any;
  contentShort: any;
  isLandscape: boolean;

  @Input() grammar: Grammar = {} as Grammar;

  constructor(
    public languageService: LanguageService,
    public wordService: WordService,
    private screensizeService: ScreensizeService,
    public utilsService: UtilsService,
  ) {
    if (utilsService.isWebVersion) {
      this.screensizeService.isLandscapeView().subscribe(isLandscape => {
        if (this.isLandscape && !isLandscape) {
          // reload bc routing is out of place
          window.location.reload();
        }

        this.isLandscape = isLandscape;
      })
    }

  }

  ngOnInit(): void {
    this.getData(this.grammar, this.languageService.target, true);
  }

  public getData(grammar: Grammar, language: string, rerun: boolean): string {
    if (this.languageService.target == 'eng') this.languageService.target = 'en'
    if (!this.grammar.grammarId.content) return '';
    if (language === 'zh-hant') language = 'zh-hant';
    if (language === 'zh-hans') language = 'zh-hans';
    for (let index = 0; index < this.grammar.grammarId.content.length; index++) {
      const element = this.grammar.grammarId.content[index];
      if (language === element.language) {
        this.name = element.name;
        this.description = element.description;
        if (element.contentLong) this.contentLong = element.contentLong
        if (element.contentShort) this.contentShort = element.contentShort
        return;
      }
    }

    if (rerun) {
      if (this.name == '' && this.description == '') {
        this.getData(grammar, 'en', false);
      }
    }

  }

  checkUnderline(element: any) {
    if (element.annotations?.underline != undefined) {
      if (element.annotations?.underline == true) return true
      if (element.annotations?.underline == false) return false
    }
    else return false;
  }

}

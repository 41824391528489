import { Component } from '@angular/core';
import { NavController, LoadingController } from '@ionic/angular';
import { UtilsService } from '../../service/utils-service/utils.service';
import { LanguageService } from '../../service/language-service/language.service';
import { TrainingService } from '../../service/training-service/training.service';
import { Storage } from '@ionic/storage';
import { TrainingDifficulty } from '@interfaces/training';
import { TabsService } from '@services/tabs-service/tabs.service';
import { WordService } from '@services/word-service/word.service';
import { environment } from 'environments/environment';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-training',
  templateUrl: './training.page.html',
  styleUrls: ['./training.page.scss'],
})
export class TrainingPage {
  environment = environment;

  constructor(
    public navCtrl: NavController,
    private utilsService: UtilsService,
    public loadingController: LoadingController,
    public languageService: LanguageService,
    public trainingService: TrainingService,
    private storage: Storage,
    private tabsService: TabsService,
    public alertController: AlertController,
    private wordService: WordService
  ) { }

  askClear = false;
  isMobile = this.utilsService.isMobile();

  ionViewWillEnter() {
    this.trainingService.stackToday = this.trainingService.stackToday.filter(word => word.word);
    this.tabsService.updateTabUi('training');
    this.utilsService.routingName = 'training';
  }


  ionViewDidEnter(): void {
    this.storage.get('openedTraining').then(res => {
      if (!res) {
        setTimeout(() => {
          this.trainingService.showTrainingHelp = true;
        }, 1000);
      }
      this.storage.set('openedTraining', true);
    });
    this.storage.get('askClearDone').then((val) => {
      if (!val && this.trainingService.stackNumberOfDone > 300) {
        setTimeout(() => {
          this.askClear = true;
          this.clearDoneModal()
        }, 500);
      }
    });
  }

  async clearDoneModal() {
    this.trainingService.stackOverview = this.trainingService.getDifficulty(TrainingDifficulty.done);
    const alert = await this.alertController.create({
      header: this.languageService.label.txt_training_modal_done_clear_title,
      message: this.languageService.label.txt_training_modal_done_clear_text,
      buttons: [
        {
          text: this.languageService.label.txt_training_modal_done_clear_keep,
          role: 'cancel',
          cssClass: 'secondary',
          // @ts-ignore
          id: 'cancel-button',
          handler: () => {
            this.storage.set('askClearDone', true);
            this.askClear = false;
          }
        }, {
          text: this.languageService.label.txt_training_modal_done_clear_delete,
          // @ts-ignore
          id: 'confirm-button',
          handler: () => {
            this.trainingService.removeTrainingBatchDataFromServer(this.trainingService.stackOverview.map(word => word.word._id));
            this.trainingService.stackFuture = this.trainingService.stackFuture.filter(word => word.difficulty != 'DONE');
            this.trainingService.stackToday = this.trainingService.stackToday.filter(word => word.difficulty != 'DONE');
            this.trainingService.saveStack(true);
            this.trainingService.stackOverview = [];
            this.trainingService.overviewWords = [];
            this.storage.set('askClearDone', false);
            this.askClear = false;
          }
        }
      ]
    });

    await alert.present();
  }

  goToExercise(): void {
    if (this.trainingService.stackToday.length > 0) {
      this.navCtrl.navigateForward('/training/training-exercise', { animated: this.isMobile });
    }
  }

  goToAllKeywords(): void {
    this.navCtrl.navigateForward('/training/training-keywords', { animated: this.isMobile });
  }

  async showOverviewEasy() {
    this.trainingService.overviewTitle = this.languageService.label.txt_training_page_easy_title;
    this.trainingService.stackOverview = this.trainingService.getDifficulty(TrainingDifficulty.easy);
    this.trainingService.overviewDifficulty = TrainingDifficulty.easy;
    await this.loadWordData();
    this.goToOverview();
  }

  async showOverviewOkay() {
    this.trainingService.overviewTitle = this.languageService.label.txt_training_page_good_title;
    this.trainingService.stackOverview = this.trainingService.getDifficulty(TrainingDifficulty.good);
    this.trainingService.overviewDifficulty = TrainingDifficulty.good;
    await this.loadWordData();
    this.goToOverview();
  }

  async showOverviewHard() {
    this.trainingService.overviewTitle = this.languageService.label.txt_training_page_hard_title;
    this.trainingService.stackOverview = this.trainingService.getDifficulty(TrainingDifficulty.hard);
    this.trainingService.overviewDifficulty = TrainingDifficulty.hard;
    await this.loadWordData();
    this.goToOverview();
  }

  async showOverviewDone() {
    this.trainingService.overviewTitle = this.languageService.label.txt_training_page_done_title;
    this.trainingService.stackOverview = this.trainingService.getDifficulty(TrainingDifficulty.done);
    this.trainingService.overviewDifficulty = TrainingDifficulty.done;
    await this.loadWordData();
    this.goToOverview();
  }

  goToOverview(): void {
    this.navCtrl.navigateForward('/training/training-overview', { animated: this.isMobile });
  }

  async loadWordData(): Promise<any> {

    return new Promise(async (resolve, reject) => {
      if (this.trainingService.stackOverview.length < 200) {
        this.trainingService.overviewWords = [];
        this.trainingService.stackOverview.forEach(async (element, index, arry) => {
          if (element.word) element.word.inTraining = true;
          if (!element.word && element.id) {
            console.log("loading:", element)
            element.word = await this.wordService.getWordData(element.id);
            element.word.inTraining = true;
          }
        });
        return resolve({});
      } else {
        const loading = await this.loadingController.create({
          spinner: "lines-small",
          duration: 5000,
          message: this.languageService.label.txt_loader_label,
          translucent: true,
          cssClass: 'custom-class custom-loading',
          backdropDismiss: false,
        });
        await loading.present();
        this.trainingService.overviewWords = [];
        await this.trainingService.stackOverview.forEach(async (element, index, arry) => {
          if (element.word) element.word.inTraining = true;
          if (!element.word && element.id) {
            console.log("loading:", element)
            element.word = await this.wordService.getWordData(element.id);
            element.word.inTraining = true;
          }
        });
        loading.dismiss();
        return resolve({});
      }
    })
  }

  get countHard(): number {
    return this.trainingService.stack.words.filter((word) => word.difficulty == TrainingDifficulty.hard).length;
  }

  get countDone(): number {
    return this.trainingService.stack.words.filter((word) => word.difficulty == TrainingDifficulty.done).length;
  }

  get countEasy(): number {
    return this.trainingService.stack.words.filter((word) => word.difficulty == TrainingDifficulty.easy).length;
  }

  get countGood(): number {
    return this.trainingService.stack.words.filter((word) => word.difficulty == TrainingDifficulty.good).length;
  }

  get countToday(): number {
    this.trainingService.stackToday = this.trainingService.stackToday.filter(word => word.word);
    return this.trainingService.stackToday.length;
  }

}

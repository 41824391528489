<div class="translate-wrapper" *ngIf="word">
  <div class="content" [ngClass]="{'fill': word.explanation}">
    <div class="singular-container">
      <div class="keyword-singular-text" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
        <span *ngIf="languageService.origin === 'es' && word.gender == 'masculine'"> el </span>
        <span *ngIf="languageService.origin === 'es' && word.gender  == 'feminine'"> la </span>
        <span *ngIf="languageService.origin === 'es' && word.gender  == 'feminin'"> la
        </span><!-- there is a typo in the gender for some words -->
        {{ word | wordArticle }} {{ word.display }}<span
          *ngIf="(languageService.origin == 'en' || languageService.origin == 'de' )&& word | wordForm: 'comparative'">,
          {{ word | wordForm: 'comparative'}}</span>
        <span
          *ngIf="(languageService.origin == 'en' || languageService.origin == 'de' )&& word | wordForm: 'superlative'">,
          {{ word | wordForm: 'superlative'}}</span>
      </div>
      <div class="keyword-singular-text" [ngClass]="{'biggerFont': this.wordService.enlargeFont}"
        *ngIf="languageService.origin == 'ja' && word.romanji">
        {{ word | wordArticle }} {{ word.hiragana }} ({{ word.romanji }})
      </div>
      <div class="keyword-singular-text" [ngClass]="{'biggerFont': this.wordService.enlargeFont}"
        *ngIf="languageService.origin == 'zh-hans' && word.pinyin">
        {{ word | wordArticle }} ({{ word.pinyin }})
      </div>
    </div>

    <div *ngIf="(word | wordForm: 'plural') && !isAdjective" class="plural-container">
      <div class="keyword-plural" style="margin-bottom: 10px;" [ngClass]="{'keyword-plural-small': UI_word_small}">
        <div class="keyword-plural-text" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
          <span *ngIf="languageService.origin === 'de'">die</span>
          {{ word | wordForm: 'plural'}}
        </div>
        <div class="keyword-plural-label" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
          {{this.languageService.label.txt_plural}}</div>
      </div>
    </div>

    <div class="translation" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">{{ word | wordTranslation }}</div>
    <div class="category-verb-wrapper">
      <div *ngIf="languageService.origin == 'ja'" class="word-type"
        [ngClass]="{'biggerFont': this.wordService.enlargeFont}"><span> {{word.category | wordCategoryLabel}}</span>
      </div>
      <div *ngIf="languageService.origin == 'zh-hans' && (word.category && word.category != 'not defined')"
        class="word-type" [ngClass]="{'biggerFont': this.wordService.enlargeFont}"><span> {{word.category |
          wordCategoryLabel}}</span>
      </div>
      <div
        *ngIf="(isVerb &&  word.tenses.length != 0) || ((this.languageService.origin == 'fr' || this.languageService.origin == 'es') && isAdjective  &&  word.forms.length != 0) || this.languageService.origin == 'ja' && this.hasForm && isAdjective"
        class="btn-conjugation" (click)="viewFullWord.emit()" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
        <span *ngIf="isVerb">{{this.languageService.label.txt_more}}</span>
        <span *ngIf="isAdjective">{{this.languageService.label.txt_more_adj}}</span>
      </div>
      <div *ngIf="this.languageService.origin == 'ja' && isVerb  &&  word.forms.length != 0" class="btn-conjugation"
        (click)="viewFullWord.emit()" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">
        <span *ngIf="isVerb">{{this.languageService.label.txt_more}}</span>
      </div>
    </div>

    <div class="definition" [ngClass]="{'biggerFont': this.wordService.enlargeFont}">{{ word | wordExplaination }}</div>
  </div>

  <div class="actions">
    <div [ngClass]="{'active': isAudioActive}" (click)="playAudio()">
      <div class="keyword-sound-btn-icon">
        <ion-icon name="play-circle"></ion-icon>
      </div>
    </div>
    <div [ngClass]="{'active': word.inTraining}" (click)="addToTraining.emit()" style="color: #fff;margin-top: 3px;">
      <ion-icon name="barbell-outline"></ion-icon>
    </div>

  </div>
</div>
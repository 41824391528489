<form [formGroup]="loginForm" (ngSubmit)="onSubmit()">

  <div class="control-item">
    <ion-item lines="none">
      <ion-input type="text" formControlName="email"
        placeholder="{{languageService.label.txt_register_placeholder_email}}"></ion-input>
      <!--<ion-icon name="mail-outline" slot="end"></ion-icon>-->
    </ion-item>
    <!-- <p *ngIf="email.errors?.required && email.touched" class="error">Email is required.</p>
    <p *ngIf="email.errors?.email && email.touched" class="error">Email format is invalid.</p> -->
  </div>
  <div class="control-item">
    <ion-item lines="none">
      <ion-input [type]="!showPassword ? 'password': 'text'" formControlName="password"
        placeholder="{{languageService.label.txt_register_placeholder_password}}"></ion-input>
      <ion-icon *ngIf="showPassword" name="eye-outline" slot="end"></ion-icon>
      <!--<ion-icon (click)="showPassword = !showPassword" *ngIf="!showPassword" name="eye-off-outline" slot="end"></ion-icon>-->
    </ion-item>
    <div class="forgot-password" (click)="forgotPassword()">{{languageService.label.txt_login_modal_forgot_password}}
    </div>
    <!-- <p *ngIf="password.errors?.required && password.touched" class="error">Password is required.</p> -->
    <!-- <p
      class="info"
      *ngIf="!password.errors?.required || !password.touched"
      [ngClass]="{ error: password.errors?.pattern && password.touched }"
    >
      Password should have at least 6 characters, 1 lowercase and 1 uppercase letter. 
    </p>-->
  </div>

  <div class="error-message" [ngClass]="{'active': isLoginError}">
    {{languageService.label.txt_login_modal_message_error}}</div>

  <button type="submit" class="submit">
    <span *ngIf="!isLoading">{{languageService.label.txt_login_modal_txt}}</span>
    <ion-spinner *ngIf="isLoading" name="crescent"></ion-spinner>
  </button>
  <div class="sign-up-wrapper" *ngIf="!disableRegister"><span
      (click)="goToSignup()">{{languageService.label.txt_register_cta}}</span> </div>

  <div class="horizontal-line">
    <div></div>
    <p>{{languageService.label.txt_login_divider_text}}</p>
    <div></div>
  </div>

  <ng-container *ngIf="platform == 'web'">
    <button class="social-login-btn apple" (click)="loginApple($event)">
      <div>
        <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M19.2806 18.424C18.9328 19.2275 18.5211 19.9672 18.0441 20.6472C17.3938 21.5743 16.8614 22.216 16.4511 22.5724C15.8151 23.1573 15.1336 23.4568 14.4039 23.4739C13.88 23.4739 13.2483 23.3248 12.5129 23.0224C11.775 22.7214 11.097 22.5724 10.477 22.5724C9.82677 22.5724 9.12941 22.7214 8.38349 23.0224C7.63644 23.3248 7.03463 23.4824 6.5745 23.498C5.87472 23.5278 5.17722 23.2197 4.48099 22.5724C4.03662 22.1848 3.48081 21.5204 2.81496 20.5791C2.10057 19.5739 1.51323 18.4084 1.0531 17.0795C0.560323 15.6442 0.313293 14.2543 0.313293 12.9087C0.313293 11.3673 0.646357 10.0379 1.31348 8.92385C1.83778 8.029 2.53528 7.32312 3.40826 6.80493C4.28124 6.28674 5.2245 6.02267 6.2403 6.00578C6.79611 6.00578 7.52499 6.1777 8.43077 6.51559C9.33399 6.85462 9.91394 7.02655 10.1682 7.02655C10.3583 7.02655 11.0026 6.82552 12.0948 6.42473C13.1276 6.05305 13.9993 5.89916 14.7134 5.95978C16.6485 6.11595 18.1023 6.87876 19.0691 8.25303C17.3385 9.30163 16.4824 10.7703 16.4994 12.6544C16.515 14.122 17.0474 15.3432 18.0937 16.3129C18.5679 16.7629 19.0975 17.1107 19.6867 17.3578C19.5589 17.7283 19.424 18.0832 19.2806 18.424ZM14.8426 0.960131C14.8426 2.11039 14.4224 3.18439 13.5847 4.17847C12.5739 5.36023 11.3513 6.04311 10.0254 5.93536C10.0085 5.79736 9.9987 5.65213 9.9987 5.49951C9.9987 4.39526 10.4794 3.21349 11.3331 2.24724C11.7593 1.75801 12.3013 1.35122 12.9586 1.02671C13.6146 0.707053 14.235 0.530273 14.8185 0.5C14.8355 0.653772 14.8426 0.807554 14.8426 0.960116V0.960131Z"
            fill="white" />
        </svg>
        <p>{{languageService.label.txt_social_login_apple}}</p>
      </div>
    </button>


    <button class="social-login-btn facebook" (click)="loginFacebook($event)">
      <div>
        <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M23 12.0699C23 5.7186 17.8513 0.56988 11.5 0.56988C5.14872 0.56988 0 5.7186 0 12.0699C0 17.8099 4.20538 22.5674 9.70312 23.4302V15.3941H6.7832V12.0699H9.70312V9.53629C9.70312 6.6541 11.42 5.06207 14.0468 5.06207C15.305 5.06207 16.6211 5.28668 16.6211 5.28668V8.11675H15.171C13.7424 8.11675 13.2969 9.00322 13.2969 9.91266V12.0699H16.4863L15.9765 15.3941H13.2969V23.4302C18.7946 22.5674 23 17.8099 23 12.0699Z"
            fill="white" />
        </svg>
        <p>{{languageService.label.txt_social_login_facebook}}</p>
      </div>
    </button>

    <button class="social-login-btn google" (click)="loginGoogle($event)">
      <div>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M23.04 12.2614C23.04 11.4459 22.9668 10.6618 22.8309 9.90912H12V14.3575H18.1891C17.9225 15.795 17.1123 17.013 15.8943 17.8284V20.7139H19.6109C21.7855 18.7118 23.04 15.7637 23.04 12.2614Z"
            fill="#4285F4" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M11.9999 23.4998C15.1049 23.4998 17.7081 22.47 19.6108 20.7137L15.8942 17.8282C14.8644 18.5182 13.5472 18.9259 11.9999 18.9259C9.00467 18.9259 6.46945 16.903 5.56513 14.1848H1.72308V17.1644C3.61536 20.9228 7.50445 23.4998 11.9999 23.4998Z"
            fill="#34A853" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M5.56523 14.1851C5.33523 13.4951 5.20455 12.758 5.20455 12.0001C5.20455 11.2421 5.33523 10.5051 5.56523 9.81506V6.83551H1.72318C0.944318 8.38801 0.5 10.1444 0.5 12.0001C0.5 13.8557 0.944318 15.6121 1.72318 17.1646L5.56523 14.1851Z"
            fill="#FBBC05" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M11.9999 5.07386C13.6883 5.07386 15.2042 5.65409 16.396 6.79364L19.6944 3.49523C17.7029 1.63955 15.0997 0.5 11.9999 0.5C7.50445 0.5 3.61536 3.07705 1.72308 6.83545L5.56513 9.815C6.46945 7.09682 9.00468 5.07386 11.9999 5.07386Z"
            fill="#EA4335" />
        </svg>
        <p>{{languageService.label.txt_social_login_google}}</p>
      </div>
    </button>
    <p class="subtle-text" *ngIf="!environment.single" [innerHTML]="languageService.label.web_login_legal_notice"></p>
    <p class="subtle-text" *ngIf="environment.single" [innerHTML]="readleLoginText"></p>
  </ng-container>

</form>
import { Injectable } from '@angular/core';
import { TrainingStack, TrainingWord, TrainingDifficulty } from '../../interfaces/training';
import { Storage } from '@ionic/storage';
import { AuthService } from '../auth-service/auth.service';
import { PurchaseService } from '../purchase-service/purchase.service';
import Word from '@interfaces/word';
import { LanguageService } from '@services/language-service/language.service';
import { DialogueContent, WordResultMap } from '@interfaces/story';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TrainingService {

  public stack: TrainingStack = {
    words: []
  } as TrainingStack;

  public stackToday: TrainingWord[] = [];
  public stackFuture: TrainingWord[] = [];

  public stackOverview: TrainingWord[] = []; // Used for the overview page
  public overviewWords: any = [];
  public overviewTitle = '';
  public overviewDifficulty: TrainingDifficulty = TrainingDifficulty.easy;

  public stackNumberOfNew = 0;
  public stackNumberOfHard = 0;
  public stackNumberOfGood = 0;
  public stackNumberOfEasy = 0;
  public stackNumberOfDone = 0;

  public trainingWord: TrainingWord = {} as TrainingWord;
  public wordData: Word = {} as Word;
  public evaluated = false;
  public cleaned = false;
  public showTrainingHelp = false;
  public toggleTrainingKeywordsObserver: Subject<any> = new Subject<any>();
  public addTrainingObserver: Subject<any> = new Subject<any>();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(
    public storage: Storage,
    public authService: AuthService,
    public purchaseService: PurchaseService,
    private languageService: LanguageService,
    private http: HttpClient
  ) {
    this.languageService.watchOrigin.subscribe((origin) => {
      this.resetStack();
      this.getStack();
    })
  }

  async getStack(): Promise<void> {
    await this.storage.get(`LANGSTER_TRAINING_STACK_${this.languageService.origin}`).then((val) => {
      if (val == null) {
        this.resetStack();
        return;
      }
      this.stack = JSON.parse(val);
      this.sortStack(this.stack);
    });
    return;
  }

  public resetStack(): void {
    this.stack = {
      words: []
    } as TrainingStack;

    this.stackToday = [];
    this.stackFuture = [];
    this.stackNumberOfNew = 0;
    this.stackNumberOfDone = 0;
    this.stackNumberOfEasy = 0;
    this.stackNumberOfGood = 0;
    this.stackNumberOfHard = 0;
  }

  private sortStack(stack: TrainingStack): void {
    let empties = []
    for (let index = 0; index < stack.words.length; index++) {
      const element = stack.words[index];
      if (this.checkDates(element.show) || element.difficulty == TrainingDifficulty.hard) {
        this.stackToday.push(element);
      } else {
        this.stackFuture.push(element);
      }
      this.countStack(element);
      this.stackNumberOfNew = this.stackToday.length;
      if (!element.word) {
        empties.push(element._id)
        this.removeFromStackToday(element)
        this.removeFromStackFuture(element)
        this.cleaned = true;
      }
    }
    if (empties.length > 0) this.removeTrainingEmptiesDataFromServer(empties)
    return;
  }

  public addToStack(id: string, sentence?: WordResultMap[], position?: number, storyId?: string, word?: Word, totalPosition?: number, updateServer = true, dialogueContent?: DialogueContent): TrainingWord {

    const trainingWord = {} as TrainingWord;
    trainingWord.id = id;

    if (sentence) trainingWord.sentence = sentence;
    if (position) trainingWord.position = position;
    if (storyId) trainingWord.storyId = storyId;
    if (word) trainingWord.word = word;
    if (totalPosition) trainingWord.totalPosition = totalPosition;
    if (dialogueContent) trainingWord.dialogue = dialogueContent;

    const inStack = this.existsInStack(id);
    if (inStack > 0) {
      this.stack.words.splice(<number>inStack - 1, 1);
      this.reduceStack(trainingWord);
      this.removeFromStackToday(trainingWord);
      this.removeFromStackFuture(trainingWord);
      this.saveStack();

      if (this.authService.token && updateServer) {
        this.removeTrainingDataFromServer(trainingWord.id);
      }

      return trainingWord;
    }

    trainingWord.show = new Date();

    if (this.authService.token && updateServer) {
      const data = this.prepareTrainingWordForServer(trainingWord);
      this.addTrainingDataToServer(data);
    }

    this.stack.words.push(trainingWord);
    this.stackToday.push(trainingWord);
    this.stackNumberOfNew++;
    this.saveStack();
    return trainingWord;
  }

  public removeFromStack(trainingWord) {
    let posInStack = this.stack.words.findIndex(word => word.id === trainingWord.id);
    console.log("pos in stack: " + posInStack)
    this.stack.words.splice(posInStack, 1);

    posInStack = this.stackToday.findIndex(word => word.id === trainingWord.id);
    console.log("pos in today: " + posInStack)
    if (posInStack > -1) this.stackToday.splice(posInStack, 1);

    posInStack = this.stackFuture.findIndex(word => word.id === trainingWord.id);
    if (posInStack > -1) this.stackFuture.splice(posInStack, 1);
    console.log("pos in fut: " + posInStack)
    this.saveStack();

  }

  public getRandomWord(): TrainingWord {
    const word = this.stackToday.shift();
    return word;
  }

  public countStack(trainingWord: TrainingWord): void {
    switch (trainingWord.difficulty) {
      case TrainingDifficulty.hard:
        this.stackNumberOfHard++;
        this.stackNumberOfNew++;
        break;
      case TrainingDifficulty.good:
        this.stackNumberOfGood++;
        break;
      case TrainingDifficulty.easy:
        this.stackNumberOfEasy++;
        break;
      case TrainingDifficulty.done:
        this.stackNumberOfDone++;
        break;
      default:
        this.stackNumberOfNew++;
        break;
    }
  }

  public reduceStack(trainingWord: TrainingWord): void {
    switch (trainingWord.difficulty) {
      case TrainingDifficulty.hard:
        if (this.stackNumberOfHard > 0) this.stackNumberOfHard--;
        break;
      case TrainingDifficulty.good:
        if (this.stackNumberOfGood > 0) this.stackNumberOfGood--;
        break;
      case TrainingDifficulty.easy:
        if (this.stackNumberOfEasy > 0) this.stackNumberOfEasy--;
        break;
      case TrainingDifficulty.done:
        if (this.stackNumberOfDone > 0) this.stackNumberOfDone--;
        break;
      default:
        if (this.stackNumberOfNew > 0) this.stackNumberOfNew--;
        break;
    }
  }

  public saveStack(reloadData = false, newData?: any[]): void {
    const newStack = {} as TrainingStack;
    newStack.userId = <any>this.authService.getUserID;
    newStack.words = this.stackToday.concat(this.stackFuture);

    if (newData) {
      newStack.words = newData;
    }

    if (this.trainingWord && this.trainingWord.id && !this.evaluated) newStack.words.push(this.trainingWord);

    this.storage.set(`LANGSTER_TRAINING_STACK_${this.languageService.origin}`, JSON.stringify(newStack)).then(() => {
      if (reloadData) {
        this.stackToday = [];
        this.stackFuture = [];
        this.stackNumberOfDone = 0;
        this.stackNumberOfEasy = 0;
        this.stackNumberOfGood = 0;
        this.stackNumberOfHard = 0;
        this.stackNumberOfNew = 0;
        this.trainingWord = {} as TrainingWord;
        this.wordData = {} as Word;
        this.getStack();
      }
    });
    return;
  }

  public addToStackTodayFuture(trainingWord: TrainingWord, difficulty: TrainingDifficulty, days: number, addToToday: boolean): void {
    if (trainingWord.difficulty == TrainingDifficulty.hard) this.stackNumberOfHard--;

    if (trainingWord.difficulty == TrainingDifficulty.easy && difficulty == TrainingDifficulty.good) this.stackNumberOfEasy--;
    if (trainingWord.difficulty == TrainingDifficulty.easy && difficulty == TrainingDifficulty.hard) this.stackNumberOfEasy--;
    if (trainingWord.difficulty == TrainingDifficulty.easy && difficulty == TrainingDifficulty.done) this.stackNumberOfEasy--;
    if (trainingWord.difficulty == TrainingDifficulty.easy && difficulty == TrainingDifficulty.easy) this.stackNumberOfEasy--;

    if (trainingWord.difficulty == TrainingDifficulty.good && difficulty == TrainingDifficulty.hard) this.stackNumberOfGood--;
    if (trainingWord.difficulty == TrainingDifficulty.good && difficulty == TrainingDifficulty.easy) this.stackNumberOfGood--;
    if (trainingWord.difficulty == TrainingDifficulty.good && difficulty == TrainingDifficulty.done) this.stackNumberOfGood--;
    if (trainingWord.difficulty == TrainingDifficulty.good && difficulty == TrainingDifficulty.good) this.stackNumberOfGood--;

    if (trainingWord.difficulty == TrainingDifficulty.done && difficulty == TrainingDifficulty.hard) this.stackNumberOfDone--;
    if (trainingWord.difficulty == TrainingDifficulty.done && difficulty == TrainingDifficulty.easy) this.stackNumberOfDone--;
    if (trainingWord.difficulty == TrainingDifficulty.done && difficulty == TrainingDifficulty.good) this.stackNumberOfDone--;
    if (trainingWord.difficulty == TrainingDifficulty.done && difficulty == TrainingDifficulty.done) this.stackNumberOfDone--;

    trainingWord.difficulty = difficulty;
    if (addToToday) {
      this.stackToday.push(trainingWord);
      this.stackNumberOfHard++;
    }
    else {
      trainingWord.show = this.addDays(new Date(), days);
      this.stackFuture.push(trainingWord);
      if (this.stackNumberOfNew > 0) this.stackNumberOfNew--;
      this.countStack(trainingWord);
    }

    if (this.authService.token) {
      const data = this.prepareTrainingWordForServer(trainingWord);
      this.updateTrainingDataOnServer(data);
    }

    this.saveStack();
  }

  private addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  private checkDates(someDate): boolean {
    const dateToCheck = new Date(someDate);
    const today = new Date();
    return (this.isToday(someDate) || (dateToCheck < today)); // true when date is today or in the past
  }

  private isToday(someDate): boolean {
    someDate = new Date(someDate);
    const today = new Date()
    return someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
  }

  public existsInStack(id: string): boolean | number {
    let found = false;
    let position = 0;
    for (let i = 0; i < this.stack.words.length; i++) {
      if (this.stack.words[i].word?._id == id) {
        found = true;
        position = i;
        break;
      }
    }

    if (found) return position + 1;
    else return found;
  }

  private removeFromStackToday(trainingWord: TrainingWord): void {
    for (let i = 0; i < this.stackToday.length; i++) {
      if (this.stackToday[i].id == trainingWord.id) {
        if (this.stackToday[i].difficulty == TrainingDifficulty.hard) this.stackNumberOfHard--;
        this.stackToday.splice(i, 1);
        this.stackNumberOfNew = this.stackToday.length;
        break;
      } else if (!this.stackToday[i].word) {
        this.stackToday.splice(i, 1);
        this.stackNumberOfNew = this.stackToday.length;
        break;
      }
    }
  }

  private removeFromStackFuture(trainingWord: TrainingWord): void {
    for (let i = 0; i < this.stackFuture.length; i++) {
      if (this.stackFuture[i].id == trainingWord.id) {
        this.reduceStack(this.stackFuture[i]);
        this.stackFuture.splice(i, 1);
        break;
      } else if (!this.stackFuture[i].word) {
        this.stackToday.splice(i, 1);
        this.stackNumberOfNew = this.stackToday.length;
        break;
      }
    }
  }

  public shuffleStackToday(): void {
    this.stackToday = this.shuffle(this.stackToday);
  }

  private shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }

  public getDifficulty(difficulty: TrainingDifficulty): any {
    return this.stack.words.filter(word => word.difficulty == difficulty);
  }

  public prepareTrainingWordForServer(trainingWord: TrainingWord) {
    const data = {};
    data['position'] = trainingWord.position;
    data['show'] = trainingWord.show;
    data['word'] = trainingWord.word?._id;
    data['storyId'] = trainingWord.storyId;
    data['totalPosition'] = trainingWord.totalPosition;
    if (trainingWord.difficulty) data['difficulty'] = this.convertDifficultyToNumber(trainingWord.difficulty);
    return data;
  }

  public convertDifficultyToNumber(difficulty: string): number {
    switch (difficulty) {
      case TrainingDifficulty.easy:
        return 1;
      case TrainingDifficulty.good:
        return 2;
      case TrainingDifficulty.hard:
        return 3;
      case TrainingDifficulty.done:
        return 4;
    }
  }

  public convertNumberToDifficulty(difficulty: number): string {
    switch (difficulty) {
      case 1:
        return TrainingDifficulty.easy;
      case 2:
        return TrainingDifficulty.good;
      case 3:
        return TrainingDifficulty.hard;
      case 4:
        return TrainingDifficulty.done;
    }
  }

  private addTrainingDataToServer(data: any) {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.api}/user/training/add`, {
        language: this.languageService.origin,
        training: data
      }, { headers: this.authService.authHeader }).subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    })
  }

  public removeTrainingDataFromServer(id: any) {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.api}/user/training/remove`, {
        language: this.languageService.origin,
        id: id
      }, { headers: this.authService.authHeader }).subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    })
  }

  public updateTrainingDataOnServer(data: any) {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.api}/user/training/update`, {
        language: this.languageService.origin,
        training: data
      }, { headers: this.authService.authHeader }).subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    })
  }

  public addTrainingBatchDataToServer(data: any[]) {
    return new Promise((resolve, reject) => {

      if (!this.authService.token) return reject();

      this.http.post(`${environment.api}/user/training/addBatch`, {
        language: this.languageService.origin,
        training: data
      }, { headers: this.authService.authHeader }).subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    })
  }

  public removeTrainingBatchDataFromServer(ids: any[]) {
    return new Promise((resolve, reject) => {
      if (ids.length == 0) return reject();
      this.http.post(`${environment.api}/user/training/removeBatch`, {
        language: this.languageService.origin,
        training: ids
      }, { headers: this.authService.authHeader }).subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    })
  }

  public removeTrainingEmptiesDataFromServer(_ids: any[]) {
    return new Promise((resolve, reject) => {
      if (_ids.length == 0) return reject();
      this.http.post(`${environment.api}/user/training/removeBatchObjects`, {
        language: this.languageService.origin,
        training: _ids
      }, { headers: this.authService.authHeader }).subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    })
  }

  public importTrainingData(data: any, language: string) {
    const copy = JSON.parse(JSON.stringify(data));
    // loop through all words and convert number to difficulty
    for (let i = 0; i < copy.length; i++) {
      if (copy[i].difficulty) {
        copy[i].difficulty = this.convertNumberToDifficulty(copy[i].difficulty);
      }
      if (!copy[i].word) {
        copy.splice(i, 1);
      }
    }

    this.saveStack(true, copy);
  }

}

import { DialogueContent, WordResultMap } from "./story";
import Word from "./word";

export interface TrainingStack {
    userId?: string,
    words: TrainingWord[]
}

export interface TrainingWord {
    id: string;
    difficulty?: string,
    show: Date,
    sentence?: WordResultMap[],
    position?: number,
    storyId?: string,
    word?: Word,
    totalPosition?: number,
    explaination?: string,
    dialogue?: DialogueContent,
    _id: string
}

export enum TrainingDifficulty {
    done = 'DONE',
    hard = 'HARD',
    easy = 'EASY',
    good = 'GOOD'
}
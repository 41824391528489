import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OnboardingGuardService } from './guards/OnboardingGuard/onboarding-guard.service';

const routes: Routes = [

  {
    path: '',
    children: [
      {
        path: '',
        canActivate: [OnboardingGuardService],
        loadChildren: () => import('./pages/tabs/tabs.module').then((m) => m.TabsPageModule),
      },
      {
        path: 'profile',
        canActivate: [OnboardingGuardService],
        loadChildren: () => import('./pages/profile/profile/profile.module').then((m) => m.ProfilePageModule)
      },
      {
        path: 'shop',
        loadChildren: () => import('./pages/shop/shop.module').then((m) => m.ShopPageModule)
      },
      {
        path: 'purchase',
        canActivate: [OnboardingGuardService],
        loadChildren: () => import('./pages/purchase/purchase.module').then((m) => m.PurchasePageModule)
      },
      {
        path: 'article/:id',
        loadChildren: () => import('./pages/article/article.module').then((m) => m.ArticlePageModule),
      },
      {
        path: 'article/:id/memorize-keywords',
        loadChildren: () => import('./pages/memorize-keywords/memorize-keywords.module').then((m) => m.MemorizeKeywordsPageModule),
      },
      {
        path: 'article/:language/:storyId',
        loadChildren: () => import('./pages/article/article.module').then((m) => m.ArticlePageModule),
      },
      {
        path: 'rate-to-unlock-story',
        loadChildren: () => import('./pages/rate-to-unlock/rate-to-unlock.module').then((m) => m.RateToUnlockPageModule),
      },
      {
        path: 'search',
        loadChildren: () => import('./pages/search/search.module').then((m) => m.SearchPageModule),
      },
      {
        path: 'my-library',
        loadChildren: () => import('./pages/my-library/my-library.module').then((m) => m.MyLibraryPageModule),
      },
      {
        path: 'my-library/favorites',
        loadChildren: () => import('./pages/favorites/favorites.module').then((m) => m.FavoritesPageModule),
      },
      {
        path: 'my-library/learned-stories',
        loadChildren: () => import('./pages/learned-stories/learned-stories.module').then((m) => m.LearnedStoriesPageModule),
      },
      {
        path: 'training',
        loadChildren: () => import('./pages/training/training.module').then((m) => m.TrainingPageModule),
      },
      {
        path: 'short-stories-library',
        loadChildren: () => import('./pages/short-stories-library/short-stories-library-routing.module').then((m) => m.ShortStoriesLibraryPageRoutingModule),
      },
      {
        path: 'short-story/:id',
        loadChildren: () => import('./pages/short-stories/short-stories-routing.module').then((m) => m.ShortStoriesPageRoutingModule),
      },
      {
        path: 'short-story/:language/:id',
        loadChildren: () => import('./pages/short-stories/short-stories-routing.module').then((m) => m.ShortStoriesPageRoutingModule),
      },
      {
        path: 'short-dialogues/:id',
        loadChildren: () => import('./pages/short-dialogues/short-dialogues-routing.module').then((m) => m.ShortDialoguesPageRoutingModule),
      },
      {
        path: 'short-dialogues/:language/:id',
        loadChildren: () => import('./pages/short-dialogues/short-dialogues-routing.module').then((m) => m.ShortDialoguesPageRoutingModule),
      },
      {
        path: 'short-dialogues/:id/:chapter/revision',
        loadChildren: () => import('./pages/revision/revision-routing.module').then((m) => m.RevisionPageRoutingModule),
      },
      {
        path: 'short-story/:id/:chapter/revision',
        loadChildren: () => import('./pages/revision/revision-routing.module').then((m) => m.RevisionPageRoutingModule),
      },
      {
        path: 'short-story/:id/revision/memorize-keywords',
        loadChildren: () => import('./pages/memorize-keywords/memorize-keywords.module').then((m) => m.MemorizeKeywordsPageModule),
      },
      {
        path: 'training/training-exercise',
        loadChildren: () =>
          import('./pages/training-exercise/training-exercise.module').then((m) => m.TrainingExercisePageModule),
      },
      {
        path: 'settings',
        canActivate: [OnboardingGuardService],
        loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule)
      },
      {
        path: 'word-detail',
        loadChildren: () => import('./pages/word-detail/word-detail.module').then(m => m.WordDetailPageModule)
      },
      // {
      //   path: 'onboarding-level',
      //   loadChildren: () => import('./pages/onboarding-level/onboarding-level.module').then( m => m.OnboardingLevelPageModule)
      // },
      // {
      //   path: 'language-select',
      //   loadChildren: () => import('./pages/language-select/language-select.module').then( m => m.LanguageSelectPageModule)
      // },
      {
        path: 'training/training-overview',
        loadChildren: () => import('./pages/training-overview/training-overview.module').then(m => m.TrainingOverviewPageModule)
      },
      {
        path: 'allow-tracking',
        loadChildren: () => import('./pages/allow-tracking/allow-tracking.module').then(m => m.AllowTrackingPageModule)
      },
      {
        path: 'onboarding-shop',
        loadChildren: () => import('./pages/onboarding-shop/onboarding-shop.module').then(m => m.OnboardingShopPageModule)
      },
      {
        path: 'start-learning',
        canActivate: [OnboardingGuardService],
        loadChildren: () => import('./pages/credit-card-purchase/credit-card-purchase.module').then(m => m.CreditCardPurchaseModule)
      }
    ]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/onboarding/onboarding.module').then((m) => m.OnboardingPageModule),
  },
  {
    path: 'user-review',
    loadChildren: () => import('./pages/social-review/social-review.module').then((m) => m.SocialReviewPageModule),
  },
  {
    path: 'editor-review',
    loadChildren: () => import('./pages/social-review/social-review.module').then((m) => m.SocialReviewPageModule),
  },
  {
    path: 'onboarding-shop',
    loadChildren: () => import('./pages/onboarding-shop/onboarding-shop.module').then(m => m.OnboardingShopPageModule)
  },
  {
    path: 'permission-notification',
    loadChildren: () => import('./pages/permission-notification/permission-notification.module').then(m => m.PermissionNotificationPageModule)
  },
  {
    path: 'permission-tracking',
    loadChildren: () => import('./pages/permission-tracking/permission-tracking.module').then(m => m.PermissionTrackingPageModule)
  },
  {
    path: 'explain-trail',
    loadChildren: () => import('./pages/explain-trail/explain-trail.module').then(m => m.ExplainTrailPageModule)
  },
  {
    path: 'onboarding-register',
    canActivate: [],
    loadChildren: () => import('./pages/onboarding-register/onboarding-register.module').then(m => m.OnboardingRegisterPageModule)
  },
  {
    path: 'create-account',
    loadChildren: () => import('./pages/create-account/create-account.module').then(m => m.CreateAccountPageModule)
  },
  {
    path: 'select-your-plan',
    loadChildren: () => import('./pages/choose-plan/choose-plan.module').then(m => m.ChoosePlanPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }

import { Pipe, PipeTransform } from '@angular/core';
import Word from '@interfaces/word';
import { LanguageService } from '@services/language-service/language.service';

@Pipe({
  name: 'wordArticle'
})
export class ArticlePipe implements PipeTransform {

  constructor(
    private languageService: LanguageService
  ){}

  transform(word: Word, ...args: unknown[]): unknown {
    if (this.languageService.origin === 'fr') {
      if (word.category === 'noun') {
        if (word.gender === 'la') return 'une';
        if (word.gender === 'le') return 'un';
      }
    }

    if (this.languageService.origin === 'de') {
      if (word.category === 'noun') {
        return word.gender;
      }
    }

    return '';
  }

}

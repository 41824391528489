import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy} from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RateComponent } from './components/rate/rate/rate.component';
import { LoaderComponent } from './components/loader/loader.component';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Market } from '@ionic-native/market/ngx';

import { InAppPurchase2 } from '@ionic-native/in-app-purchase-2/ngx';
import { Globalization } from '@ionic-native/globalization/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

import { environment } from '../environments/environment';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { TapticEngine } from '@ionic-native/taptic-engine/ngx';
import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
import { Appsflyer } from "@ionic-native/appsflyer/ngx";
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { Media } from '@ionic-native/media/ngx';

// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import 'firebase/analytics';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TrainingHelpComponent } from '@components/training-help/training-help.component';
import { StreakHelpComponent } from '@components/streak-help/streak-help.component';
import { NoConnectionComponent } from '@components/no-connection/no-connection.component';
import { PipesModule } from '@pipes/pipes.module';
import { AppRate } from '@ionic-native/app-rate/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { FirebaseCrashlytics } from '@awesome-cordova-plugins/firebase-crashlytics/ngx';
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { LaunchReview } from '@awesome-cordova-plugins/launch-review/ngx';
import { Facebook } from '@ionic-native/facebook/ngx';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { LoginPageModule } from '@pages/login/login.module';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { SignInWithApple } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import Bugsnag from '@bugsnag/js'
import { StripeModule } from "stripe-angular"
import { TransformCurrencyAmountPipe } from '@pipes/transform-currency-amount.pipe';
//import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'

Bugsnag.start('53a40bb221f4579681371f789bf8e5df')
//Bugsnag.notify(new Error('Something went wrong'))

// create a factory which will return the Bugsnag error handler
// export function errorHandlerFactory() {
//   return new BugsnagErrorHandler(client);
// }

@NgModule({
  declarations: [
    AppComponent,
    RateComponent,
    LoaderComponent,
    TrainingHelpComponent,
    StreakHelpComponent,
    NoConnectionComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot({
      name: environment.localDBName,
      driverOrder: [CordovaSQLiteDriver._driver, 'indexeddb', 'websql', 'localstorage'],
    }),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    PipesModule,
    LoginPageModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
    }),
    StripeModule.forRoot(environment.stripe.key)
  ],
  exports: [],
  providers: [
    StatusBar,
    SplashScreen,
    Globalization,
    AppVersion,
    SocialSharing,
    Market,
    InAppPurchase2,
    InAppBrowser,
    MobileAccessibility,
    FirebaseCrashlytics,
    GoogleAnalytics,
    FirebaseAnalytics,
    ScreenOrientation,
    OneSignal,
    TapticEngine,
    NativeAudio,
    Media,
    Appsflyer,
    UniqueDeviceID,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: 'googleTagManagerId', useValue: environment.gtm.id },
    AppRate,
    LaunchReview,
    Facebook,
    GooglePlus,
    Deeplinks,
    SignInWithApple,
    Clipboard,
    TransformCurrencyAmountPipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavController } from '@ionic/angular';
import { TrainingService } from '@services/training-service/training.service';
import { LanguageService } from '../../service/language-service/language.service';
import { Location } from '@angular/common';
import { UtilsService } from '@services/utils-service/utils.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() title: string;
  @Input() secondaryTitle = false;
  @Input() separetedTitle = false;
  @Input() settings = false;
  @Input() titleLeft = false;
  @Input() profile = false;
  @Input() back = false;
  @Input() trainingInfo = false;
  @Input() trainingOverview = false;
  @Input() learnedFilter = false;
  @Input() learnFilterState = false;
  @Input() nextLevelAvailable = true;
  @Input() previousLevelAvailable = true;

  @Output() clearTrainingList = new EventEmitter<any>();
  @Output() toggleLearnedFilter = new EventEmitter<boolean>();
  @Output() previousLevel = new EventEmitter<any>();
  @Output() nextLevel = new EventEmitter<any>();
  @Output() onBack = new EventEmitter<any>();

  constructor(
    public navCrtl: NavController,
    public languageService: LanguageService,
    private trainingService: TrainingService,
    private location: Location,
    public utilsService: UtilsService,
  ) { }

  goBack(): void {
    if (!this.back) return;
    const currentUrl = this.location.path();
    if (currentUrl && currentUrl.includes('settings/')) {
      this.goToSettings();
      return
    }
    if (currentUrl && currentUrl.includes('settings')) {
      this.goToProfile();
      return
    }
    if (currentUrl && currentUrl.includes('profile')) {
      if (this.utilsService.routingName == 'flashFiction') this.navCrtl.navigateRoot("/short-stories-library");
      else this.navCrtl.navigateRoot("/home");
      return
    }
    if (currentUrl && currentUrl.includes('favorites') || currentUrl && currentUrl.includes('learned-stories')) {
      this.navCrtl.navigateBack("/my-library");
      return
    }
    this.navCrtl.back();
    this.onBack.emit();
  }

  goToSettings(): void {
    this.navCrtl.navigateForward('/settings');
  }

  goToProfile(): void {
    this.navCrtl.navigateForward('/profile');
  }

  showTrainingInfo(): void {
    this.trainingService.showTrainingHelp = true;
  }

  clearTrainingListEvent(): void {
    this.clearTrainingList.emit();
  }

  triggerPreviousLevel(): void {
    if (this.previousLevelAvailable) this.previousLevel.emit();
  }

  triggerNextLevel(): void {
    if (this.nextLevelAvailable) this.nextLevel.emit();
  }

}


<app-header [title]="languageService.label.txt_forgot_password_title" [back]="true" [titleLeft]="true"></app-header>
<ion-content scroll-y="true" style="position: relative">


    <div class="container" [ngClass]="{'readle': isReadle}">

        <div *ngIf="!isReset && !isSuccess && !isResetSuccess">
            <p>{{languageService.label.txt_forgot_password_text}}</p>
        
            <div class="control-item">
                <ion-item lines="none">
                  <ion-input type="text" [(ngModel)]="inputEmail" [placeholder]="languageService.label.txt_register_placeholder_email"></ion-input>
                </ion-item>
            </div>
            
            <button (click)="sendLink()">
                <span *ngIf="!isPending">{{languageService.label.txt_forgot_password_cta}}</span>
                <ion-spinner *ngIf="isPending" name="crescent"></ion-spinner>
            </button>
        </div>

        <div *ngIf="isSuccess" class="wrapper-success">

            <ion-icon name="checkmark-circle-outline"></ion-icon>
            <h1>{{languageService.label.txt_forgot_password_success_title}}</h1>
            <p>{{languageService.label.txt_forgot_password_succes_text}}</p>
            <!--
            <button (click)="launchMailApp()">
                Open Mail <ion-icon name="arrow-forward-outline"></ion-icon>
            </button>
            -->

        </div>

        <div *ngIf="isReset" class="wrapper-reset">

            <h4>{{languageService.label.txt_reset_password_title}}</h4>
            <p>
                {{languageService.label.txt_reset_password_text}}
            </p>

            <form [formGroup]="passwordForm">
                <div class="control-item">
                    <ion-item lines="none" style="margin-bottom: 10px;">
                        <ion-input type="password" formControlName="password" [placeholder]="languageService.label.txt_reset_password_placeholder_new"></ion-input>
                        
                        <div *ngIf="password?.touched && password?.valid">
                            <ion-icon class="input-icon-valid" name="checkmark-circle" style="margin-left: 10px;"></ion-icon>
                          </div>
                  
                          <div *ngIf="password?.touched && !password?.valid">
                            <ion-icon class="input-icon-invalid" name="close-circle" style="margin-left: 10px;"></ion-icon>
                          </div>
                    </ion-item>

                    <ion-item lines="none">
                        <ion-input type="password" formControlName="passwordRepeat" [placeholder]="languageService.label.txt_reset_password_placeholder_repeat"></ion-input>
                    </ion-item>
                </div>

                <div class="error" *ngIf="isRepeatPasswordError" style="margin-bottom: 10px;">
                    {{languageService.label.txt_reset_password_title_repeat_error}}
                </div>
            </form>
            

            <button (click)="resetPassword()">
                <span *ngIf="!isResetPending">{{languageService.label.txt_reset_password_cta}}</span>
                <ion-spinner *ngIf="isResetPending" name="crescent"></ion-spinner>
            </button>

            <p *ngIf="isResetError" class="error">{{languageService.label.txt_reset_password_error}}</p>


        </div>

        <div *ngIf="isResetSuccess" class="wrapper-success">
            <ion-icon name="checkmark-circle-outline"></ion-icon>
            <h1>{{languageService.label.txt_reset_password_success_title}}</h1>
            <p>{{languageService.label.txt_reset_password_success_text}}</p>
            <!--<button (click)="goToLogin()">
                {{languageService.label.txt_reset_password_success_cta}} <ion-icon name="arrow-forward-outline"></ion-icon>
            </button>
        -->
        </div>

    </div>

  
  
</ion-content>
  